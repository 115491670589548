import React from "react";

const Au = () => {
  return (
    <div className="container py-4">
      <p className="mb-2">
        Budget Car Rental is one of the world’s best-known car rental brands
        with approximately 3,350 locations in more than 120 countries.
      </p>
      <p className="mb-2">
        Budget is an industry leader in providing vehicle rental services to
        value-conscious travellers and also operates the second-largest truck
        rental business in the United States, through a network of approximately
        1,650 locations. Budget is owned by Avis Budget Group, Inc.
      </p>
      <p className="mb-2">
        As our name suggests we've always been synonymous with great value for
        money.
      </p>
      <p className="mb-2">
        For your peace of mind all our vehicles are safety checked and cleaned
        before every rental. And because we are constantly renewing our fleet
        you’ll be driving some of the very latest vehicles all at low prices.
      </p>
    </div>
  );
};

export default Au;
