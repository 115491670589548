import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import ContentFraudScam from "../components/customer-care/ContentFraudScam";
import Header from "../common/header/Header";
import Title from "../Title";

const FraudScams = () => {
  Title("Customer Care");
  return (
    <>
      <HeaderOne />
      <Header
        title="Customer Care"
        subtitle="Question? You've come to the right place!"
      />
      <ContentFraudScam />
      <FooterOne />
    </>
  );
};

export default FraudScams;
