import React from "react";

export default class PS extends React.Component {
  render() {
    const myStyle = {
      height: "120px",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Budget Rental Car Programs</h1>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Qatar Airlines</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Burgundy member can get 2 base Avios per USD spent. Silver
                  member can get 2 base Avios and 1 bonus Avios per USD spent.
                  Gold member can get 2 base Avios and 2 bonus Avios per USD
                  spent.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Platinum member can get 2 base Avios and 3 bonus Avios per USD
                  spent.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Employee Programme member can get 10% discount for qualifying
                  rentals.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Quote your membership number during reservation and present
                  your membership card at counter upon car pick up to earn
                  miles.
                </p>
              </div>
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Singapore Airlines</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  All tier levels member can get 500 miles per qualifying rental
                  based on retail leisure rates.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Quote your membership number during reservation and present
                  your membership card at counter upon car pick up to earn
                  miles.les
                </p>
              </div>
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Turkish Airlines</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  All tier levels member can get 500 miles per qualifying
                  rental. Employee Programme member can get 10% discount per
                  qualifying rental.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Quote your membership number during reservation and present
                  your membership card at counter upon car pick up to earn
                  miles.
                </p>
              </div>
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Master Card</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  World Elite, World, Platinum, Gold, Titanium, Business member
                  can get flat 10% discount.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Quote your membership number during reservation and present
                  your membership card at counter upon car pick up to earn
                  miles.
                </p>
              </div>
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Visa</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Infinite, Infinite Privilege, Signature, Platinum, and Gold
                  member can get 10& discount globally (residents based in EAMEA
                  – Europe, Middle East, Africa and Asia).
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Quote your membership number during reservation and present
                  your membership card at counter upon car pick up to earn
                  miles.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
