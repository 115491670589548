import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import InfoGuarantee from "../components/guarantee/InfoGuarantee";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Banner from "../components/rent/Banner";

const BestRateGuarantee = () => {
  const breadcrumb = ["Products & Services", "Services", "Best Rate Guarantee"];

  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Banner
        rent="Get Our Best Rates Guaranteed at Budget.com"
        image="banner-support.jpg"
        type="support"
        sub=""
      />
      <div className="container py-5 px-lg-5">
        <InfoGuarantee />
        <hr />
        <h2 className="mt-5">Terms and Conditions</h2>
        <p className="mt-3">
          Applies to rentals at participating locations in the United States. A
          prepaid reservation must be completed at least 72 hours in advance of
          your rental by going directly to Budget.com and booking the lowest
          available rate for that rental car type. You must also provide us with
          evidence of the source of the lower rate on the claim form, to include
          but not be limited to:
        </p>
        <ol className="mt-3 listNumber">
          <li>Rental and return date and time;</li>
          <li>Pick-up and return location (must be same location);</li>
          <li>Vehicle type;</li>
          <li>Lower fare amount; and</li>
          <li>Website address.</li>
        </ol>
        <p className="mt-3">
          From the evidence provided, we must be able to verify the lower online
          published rate is not available at Budget.com. We will verify the
          comparison rental car rate within 48 hours of receiving your claim and
          notify you of the results via email. If the evidence provided is
          insufficient to verify the claim, we will contact you to provide us
          with additional evidence as necessary. If additional evidence is
          required, it must be provided in a timely manner or your claim may not
          be verified before your rental.
        </p>
        <p className="mt-3">
          If approved, the lower rate and 10% discount will be processed within
          ten (10) corporate business days from our receipt of your claim.
        </p>
        <p className="mt-3 mb-5">
          Budget reserves the right to modify the Best Rate Guarantee terms or
          to restrict its availability to any person, at any time, for any or no
          reason, and without prior notice or liability. Budget reserves the
          right to deny or reject a claim as a result of an Budget or
          third-party printing, technical, or other error, or where such claim
          is, in our sole discretion, made fraudulently or in bad faith. The
          failure by Budget to enforce any term or condition herein shall not
          constitute a waiver of that or any other term or condition.
        </p>
        <hr />
      </div>
      <FooterOne />
    </>
  );
};

export default BestRateGuarantee;
