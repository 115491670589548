import React, { useEffect, useState, Component } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { getToken } from "../../core/functions";

// export default
export default class CCN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviewFeature: false, // pay now
      carPreviews: {},
      awdCode: "",
      carsAwdInputted: [],
      cars2: [],
      cars: [],
      rate_code: "",
      carsIndex: [],
      price: [],
      specs: [],
      res: [],
      msgResult: "Finding recommended cars for you...",
    };
  }



  componentDidMount() {
    var token2 = localStorage.getItem("token");
    var bebe = localStorage.getItem("bebe");
    localStorage.clear();
    localStorage.setItem("token", token2);
    localStorage.setItem("bebe", bebe);
    const date1 = new Date(this.props.before.date);
    const date2 = new Date(this.props.before.return_date);
    const iso1 = date1.toISOString();
    const iso2 = date2.toISOString();
    const thiss = this;

    console.log("did mount");
    thiss.setState({ cars2: this.props.cars2 });
    thiss.setState({ carsAwdInputted: this.props.carsAwd });

    {
      getToken();
      Axios.get(
        "https://backend.avis-id.com/public/api/booking/car",
        // 'https://backend.avis-id.com/public/api/booking/car',
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          // handle success
          // console.log(response.data);
          return response.data;
        })
        .then(function (data) {
          // handle success
          console.log(data);
          thiss.setState({ cars: data });
          thiss.setState({ price: data.price })
          thiss.setState({ specs: data.specs })
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });

      const token = getToken();
      // console.log("PROPS: " + );
      Axios.get(
        "https://stage.abgapiservices.com/cars/catalog/v2/vehicles?brand=Budget&pickup_date=" +
        iso1.split(".", 1)[0] +
        "&pickup_location=" +
        this.props.before.location +
        "&membership_code&dropoff_date=" +
        iso2.split(".", 1)[0] +
        "&dropoff_location=" +
        this.props.before.return_location +
        "&country_code=" +
        this.props.before.country +
        "&discount_code=" +
        this.props.before.bcd_number +
        "&coupon_code=" +
        this.props.before.coupon_code +
        "&transaction_id&member_code",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("bebe"),
            client_id: "04e19f27",
          },
        }
      )
        .then(function (response) {
          // handle success
          // console.log(response.data);
          return response.data;
        })
        .then(function (data) {
          // handle success
          console.log(data);
          thiss.setState({
            cars2: data.vehicles.sort((a, b) => {
              return a.rate_totals.pay_later.reservation_total - b.rate_totals.pay_later.reservation_total;
            })
          });
          thiss.setState({ res: data.reservation });
          localStorage.setItem('codeLocation', data.reservation.pickup_location?.location?.code)
          localStorage.setItem('nameLocation', data.reservation.pickup_location?.location?.name)
          localStorage.setItem('telephoneLocation', data.reservation.dropoff_location?.location?.telephone)
          localStorage.setItem('addressLine1Location', data.reservation.dropoff_location?.address?.address_line_1)
          localStorage.setItem('addressLine2Location', data.reservation.dropoff_location?.address?.address_line_2)
          localStorage.setItem('cityLocation', data.reservation.dropoff_location?.address?.city)
          localStorage.setItem('stateLocation', data.reservation.dropoff_location?.address?.state_name)
          localStorage.setItem('postalCodeLocation', data.reservation.dropoff_location?.address?.postal_code)
          // console.log(cars2);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          thiss.setState({ msgResult: "No cars available for your search criteria." });
          if (error.response?.status == 401) {
            window.location.reload();
          } else if (error.response?.status == 400) {
            thiss.setState({ msgResult: error.response.data.status.errors[0].details });
          }
        })
        .finally(function () { });
    }
  }

  handleAwdInput() {
    console.log("AWD CODE : " + this.state.awdCode);

    Axios.get(
      "https://stage.abgapiservices.com/cars/catalog/v2/vehicles?brand=Budget&pickup_date=" +
      this.props.iso1.split(".", 1)[0] +
      "&pickup_location=" +
      this.props.before.location +
      "&dropoff_date=" +
      this.props.iso2.split(".", 1)[0] +
      "&dropoff_location=" +
      this.props.before.return_location +
      "&country_code=" +
      this.props.before.country +
      "&coupon_code=" +
      this.props.before.coupon_code +
      "&transaction_id&member_code",
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("bebe"),
          client_id: "04e19f27",
        },
      }
    )
      .then(function (response2) {
        // handle success
        // console.log(response2.data);
        return response2.data;
      })
      .then(function (data2) {
        // handle success
        console.log("AWD LENGTH : " + data2.vehicles);
        this.setState({ carsAwdInputted: data2.vehicles });
        // console.log(cars2);
      })
      .catch(function (error2) {
        // handle error
        console.log(error2);
        if (error2.response && error2.response.status == 500 || error2.response.status == 400) {
          alert(
            "AWD Code is not found. Please use the right AWD Code or leave it blank"
          );
        }
      });
  }

  togglePreviewFeature(index) {
    const { carPreviews } = this.state;
    this.setState({
      carPreviews: {
        ...carPreviews,
        [index]: !carPreviews[index], // Toggle preview feature for the clicked car
      },
    });
  }


  formatDate(va) {
    const options = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(va);
    return date.toLocaleDateString(undefined, options);
  }

  gotoExtra(vehicle, cars, total, img, name, model, make) {
    this.props.navigate("/extra", {
      state: {
        location: this.props.before.location,
        date: this.props.before.date,
        time: this.props.before.time,
        return_location: this.props.before.return_location,
        return_date: this.props.before.return_date,
        return_time: this.props.before.return_time,
        age: this.props.before.age,
        country: this.props.before.country,
        user_id: this.props.before.user_id,
        offer_code: this.props.before.offer_code,
        vehicle: vehicle,
        cars: cars,
        total: total,
        img: img,
        name: name,
        model: model,
        make: make,
        typeOrder: "PL",
        rate_code: this.state.rate_code,
      },
    });
  }

  handleSortChange(value) {
    let carsWantSort = this.state.carsAwdInputted == undefined ? this.state.cars2 : this.state.carsAwdInputted;
    const sortedCars = [...carsWantSort]; // Create a copy of the array

    if (value === "lowHigh") {
      sortedCars.sort((a, b) => {
        return a.rate_totals.pay_later.reservation_total - b.rate_totals.pay_later.reservation_total;
      });
    } else {
      sortedCars.sort((a, b) => {
        return b.rate_totals.pay_later.reservation_total - a.rate_totals.pay_later.reservation_total;
      });
    }

    // Update state with the sorted array
    if (this.state.carsAwdInputted == undefined) {
      this.setState({ cars2: sortedCars });
    } else {
      this.setState({ carsAwdInputted: sortedCars });
    }
  }


  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myLink = {
      color: "rgb(237, 85, 5)",
      cursor: "pointer",
    };
    const myBorder = {
      borderBottom: "2px solid rgb(237, 85, 5)",
    };
    const myLinkDis = {
      color: "rgb(168, 168, 168)",
    };
    const myBorderDis = {
      borderBottom: "2px solid rgb(168, 168, 168)",
    };
    const selectLink = {
      border: "2px solid rgb(237, 85, 5)",
      color: "#140a55",
    };
    const btnLink = {
      backgroundColor: "rgb(247, 139, 0)",
      color: "rgb(255, 255, 255)",
      padding: "0px 50px",
      // height: "50px"
      width: "40%",
    };
    const btnLink2 = {
      backgroundColor: "rgb(32,40,112)",
      color: "rgb(255, 255, 255)",
      padding: "0px 50px",
      // height: "50px"
    }
    return (
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <p style={myLinkDis}>1</p>
                <p style={myBorderDis}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLink}>2 Select a Car</p>
                <p style={myBorder}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLinkDis}>3</p>
                <p style={myBorderDis}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLinkDis}>4</p>
                <p style={myBorderDis}></p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-4">
              <div className="col-sm-6 col-lg-4">
                <p style={myLink}>
                  <b>Pick-Up</b>
                </p>
                <p>
                  <b>{localStorage.getItem('nameLocation') !== "undefined" && localStorage.getItem('nameLocation') !== null ? localStorage.getItem('nameLocation') : ""}</b><br />
                  {localStorage.getItem('cityLocation') !== "undefined" && localStorage.getItem('cityLocation') !== null ? localStorage.getItem('cityLocation') + "," : ""}
                  {localStorage.getItem('codeLocation') !== "undefined" && localStorage.getItem('codeLocation') !== null ? localStorage.getItem('codeLocation') + "," : ""}<br />
                  {localStorage.getItem('addressLine1Location') !== "undefined" && localStorage.getItem('addressLine1Location') !== null ? localStorage.getItem('addressLine1Location') + "," : ""}
                  {localStorage.getItem('addressLine2Location') !== "undefined" && localStorage.getItem('addressLine2Location') !== null ? localStorage.getItem('addressLine2Location') + "," : ""}
                  {localStorage.getItem('postalCodeLocation') !== "undefined" && localStorage.getItem('postalCodeLocation') !== null ? localStorage.getItem('postalCodeLocation') + "," : ""}
                  {localStorage.getItem('countryCodePick') !== "undefined" && localStorage.getItem('countryCodePick') !== null ? localStorage.getItem('countryCodePick') : ""}<br />
                  <b>{localStorage.getItem('telephoneLocation') !== "undefined" && localStorage.getItem('telephoneLocation') !== null ? localStorage.getItem('telephoneLocation') : ""}</b><br />
                  <b>Location Code: </b> {localStorage.getItem('codeLocation') !== "undefined" && localStorage.getItem('codeLocation') !== null ? localStorage.getItem('codeLocation') : ""}
                </p>
                <p>
                  <b>
                    {this.props.before.time}{" "}
                    {this.formatDate(this.props.before.date)}
                  </b>
                </p>
              </div>
              <div className="col-sm-6 col-lg-4 border-start">
                <p style={myLink}>
                  <b>Return</b>
                </p>
                <p>
                  <b>{localStorage.getItem('nameLocation') !== "undefined" && localStorage.getItem('nameLocation') !== null ? localStorage.getItem('nameLocation') : ""}</b><br />
                  {localStorage.getItem('cityLocation') !== "undefined" && localStorage.getItem('cityLocation') !== null ? localStorage.getItem('cityLocation') + "," : ""}
                  {localStorage.getItem('codeLocation') !== "undefined" && localStorage.getItem('codeLocation') !== null ? localStorage.getItem('codeLocation') + "," : ""}<br />
                  {localStorage.getItem('addressLine1Location') !== "undefined" && localStorage.getItem('addressLine1Location') !== null ? localStorage.getItem('addressLine1Location') + "," : ""}
                  {localStorage.getItem('addressLine2Location') !== "undefined" && localStorage.getItem('addressLine2Location') !== null ? localStorage.getItem('addressLine2Location') + "," : ""}
                  {localStorage.getItem('postalCodeLocation') !== "undefined" && localStorage.getItem('postalCodeLocation') !== null ? localStorage.getItem('postalCodeLocation') + "," : ""}
                  {localStorage.getItem('countryCodePick') !== "undefined" && localStorage.getItem('countryCodePick') !== null ? localStorage.getItem('countryCodePick') : ""}<br />
                  <b>{localStorage.getItem('telephoneLocation') !== "undefined" && localStorage.getItem('telephoneLocation') !== null ? localStorage.getItem('telephoneLocation') : ""}</b><br />
                  <b>Location Code: </b> {localStorage.getItem('codeLocation') !== "undefined" && localStorage.getItem('codeLocation') !== null ? localStorage.getItem('codeLocation') : ""}
                </p>
                <p>
                  <b>
                    {this.props.before.return_time}{" "}
                    {this.formatDate(this.props.before.return_date)}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-4">
              <div className="col-sm-12 col-lg-12">
                <hr></hr>
              </div>
              {/* <div className='col-sm-6 col-lg-4'>
                              <a href="#" style={myLink}>Modify Rental Details</a>
                          </div> */}
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-2 align-items-center">
              {/* <div className="col-lg-3">
                <div className="input-group mb-2">
                  <span className="input-group-text" id="basic-addon1">
                    Showing:
                  </span>
                  <select className="form-control">
                    <option>All Vehicles</option>
                  </select>
                </div>
              </div> */}
              <div className="col-lg-3">
                <div className="input-group mb-2">
                  <span className="input-group-text" id="basic-addon2">
                    Sort by:
                  </span>
                  <select className="form-control" onChange={(e) => this.handleSortChange(e.target.value)}>
                    <option value={"lowHigh"}>Low to High</option>
                    <option value={"highLow"}>High to Low</option>
                  </select>
                </div>
              </div>
              {/* <div className="col-lg-3">
                <div className="input-group mb-2">
                  <span className="input-group-text" id="basic-addon2">Add:</span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Add Discount Code"
                    value={this.state.awdCode}
                    onChange={(e) => this.setState({ awdCode: e.target.value })}
                  />
                </div >
              </div >
              <div className="col-lg-3">
                <div className="input-group mb-2">
                  <button style={{ ...btnLink, padding: "5px", borderRadius: "5px" }} onClick={() => this.handleAwdInput()}>Submit</button>
                </div>
              </div> */}
            </div >
          </div >
        </section >
        <section >
          <div className="container">
            {this.state.cars2?.length > 0 ? (
              this.state.cars2?.map((ic, i) => (
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-3" style={{ cursor: "pointer" }}>
                        {this.state.carPreviews[i] ? (
                          <div
                            className="row justify-content-between"
                            onClick={() => this.togglePreviewFeature(i)}
                          >
                            <div className="col-sm-6">
                              <p style={{ fontSize: "13px" }}>
                                <i class="fas fa-door-open"></i>{" "}
                                {ic.capacity.doors} Doors
                              </p>
                              <p style={{ fontSize: "13px" }}>
                                <i class="fas fa-wind"></i>{" "}
                                {ic.features.air_conditioned == true
                                  ? "Air Conditioning"
                                  : ""}
                              </p>
                              <p style={{ fontSize: "13px" }}>
                                <i class="fas fa-gas-pump"></i>{" "}
                                {ic.capacity.mpg} mpg
                              </p>
                              <p style={{ fontSize: "13px" }}>
                                <i class="fas fa-suitcase-rolling"></i>{" "}
                                {ic.capacity.luggage_capacity.large_suitcase}{" "}
                                Large Bags
                              </p>
                            </div>
                            <div className="col-sm-6">
                              <p style={{ fontSize: "13px" }}>
                                <i className="fas fa-user"></i>{" "}
                                {ic.capacity.seats} Seats
                              </p>
                              <p style={{ fontSize: "13px" }}>
                                <i class="fas fa-car"></i>{" "}
                                {ic.category.vehicle_transmission}
                              </p>
                              <p style={{ fontSize: "13px" }}>
                                <i class="fas fa-suitcase"></i>{" "}
                                {ic.capacity.luggage_capacity.small_suitcase}{" "}
                                Small Bags
                              </p>
                            </div>
                          </div>
                        ) : (
                          <img
                            src={ic.category.small_image_url}
                            className="img-fluid"
                            onClick={() => this.togglePreviewFeature(i)}
                          />
                        )}
                      </div>
                      <div className="col-sm-3">
                        <p>
                          <h4 className="text-dark">{ic.category.name}</h4>
                        </p>
                        <p>
                          {ic.category.make} {ic.category.model}
                        </p>
                        <p>
                          <img src="/assets/images/icon/user.png" />{" "}
                          {ic.capacity.seats}
                          <img
                            src="/assets/images/icon/suitcase.png"
                            className="ms-2"
                          />{" "}
                          {ic.capacity.luggage_capacity.large_suitcase}L
                          <img
                            src="/assets/images/icon/suitcase.png"
                            className="ms-2"
                          />{" "}
                          {ic.capacity.luggage_capacity.large_suitcase}S
                        </p>
                        <p>{ic.category.vehicle_transmission}</p>
                        <p
                          onClick={() => this.togglePreviewFeature(i)}
                          style={myLink}
                        >
                          {this.state.carPreviews[i]
                            ? "Close Features"
                            : "See More Features"}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        {this.props.before.countryPick == "US" ? (
                          <>
                            <div className="row justify-content-between mt-4">
                              <div className="col-sm-5">
                                <h4 className="text-dark">APA Inclusive</h4>
                              </div>
                              <div className="col-sm-5">
                                <h4 className="text-dark">
                                  {ic.rate_totals.rate?.currency}{" "}
                                  {ic.rate_totals.pay_later.reservation_total}
                                </h4>
                              </div>
                              <div className="col-sm-1">
                                <input
                                  type="radio"
                                  name="rate_code"
                                  className="form-check-input"
                                  value={"B2"}
                                  onChange={(e) =>
                                    this.setState({ rate_code: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div className="row justify-content-between mt-4">
                              <div className="col-sm-5">
                                <h4 className="text-dark">APA GAS Inclusive</h4>
                              </div>
                              <div className="col-sm-5">
                                <h4 className="text-dark">
                                  {ic.rate_totals.rate?.currency}{" "}
                                  {ic.rate_totals.pay_later.reservation_total}
                                </h4>
                              </div>
                              <div className="col-sm-1">
                                <input
                                  type="radio"
                                  name="rate_code"
                                  className="form-check-input"
                                  value={"J9"}
                                  onChange={(e) =>
                                    this.setState({ rate_code: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                          </>
                        ) : this.props.before.countryPick == "CA" ? (
                          <div className="row justify-content-between mt-4">
                            <div className="col-sm-5">
                              <h4 className="text-dark">Asia All Inclusive</h4>
                            </div>
                            <div className="col-sm-5">
                              <h4 className="text-dark">
                                {ic.rate_totals.rate?.currency}{" "}
                                {ic.rate_totals.pay_later.reservation_total}
                              </h4>
                            </div>
                            <div className="col-sm-1">
                              <input
                                type="radio"
                                name="rate_code"
                                className="form-check-input"
                                value={"JAI"}
                                onChange={(e) =>
                                  this.setState({ rate_code: e.target.value })
                                }
                              />
                            </div>
                          </div>
                        ) : this.props.before.awd_number != "" ? (
                          <>
                            <div className="row justify-content-between mt-4">
                              <div className="col-sm-5">
                                <h4 className="text-dark">Basic Rate</h4>
                              </div>
                              <div className="col-sm-5">
                                <h4 className="text-dark">
                                  {ic.rate_totals.rate?.currency}{" "}
                                  {ic.rate_totals.pay_later.reservation_total}
                                </h4>
                              </div>
                              <div className="col-sm-1">
                                <input
                                  type="radio"
                                  name="rate_code"
                                  className="form-check-input"
                                  value={ic.rate_totals.rate.rate_code}
                                  onChange={(e) =>
                                    this.setState({ rate_code: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="row justify-content-between">
                            <div className="col-sm-5">
                              <h4 className="text-dark">Basic Rate</h4>
                            </div>
                            <div className="col-sm-5">
                              <h4 className="text-dark">
                                {ic.rate_totals.rate?.currency}{" "}
                                {ic.rate_totals.pay_later.reservation_total}
                              </h4>
                            </div>
                            <div className="col-sm-1">
                              <input
                                type="radio"
                                name="rate_code"
                                className="form-check-input"
                                value={ic.rate_totals.rate.rate_code}
                                onChange={(e) =>
                                  this.setState({ rate_code: e.target.value })
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="row justify-content-end mt-5">
                          <button
                            className="btn py-2"
                            style={{ ...btnLink }}
                            onClick={(e) => this.gotoExtra(ic, "PL")}
                          >
                            Choose
                          </button>
                          {/* <button className="btn py-2" style={{ ...btnLink }} onClick={(e) => this.gotoExtra(ic, ic.rate_totals.pay_later.sipp_code, ic.rate_totals.pay_later.reservation_total, ic.category.small_image_url, ic.category.name, ic.category.model, ic.category.make)}>Pay Now</button> */}
                        </div>
                      </div>
                      {/* {ic.rate_totals.pay_now ? (<div className='col-sm-2'>
                                                <h4 className='text-dark'>$ {ic.rate_totals.pay_later.reservation_total}</h4>
                                                <button className="btn" style={btnLink2} onClick={(e) => this.gotoExtra(ic, 'PN')}>Pay Now</button>
                                            </div>) : ("")} */}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <hr></hr>
                  </div>
                </div>
              ))
            ) : (
              <div className="row mt-4 py-4">
                <div className="col-sm-12 text-center">
                  <h1>{this.state.msgResult}</h1>
                </div>
              </div>
            )}
          </div>
        </section >
        {/* End Slogan One */}
      </>
    );
  }
}

// function CC(props) {
//   let navigate = useNavigate();
//   const [cars, setCars] = useState([]);
//   const [specs, setSpecs] = useState([]);
//   const [price, setPrice] = useState([]);
//   const [cars2, setCars2] = useState([]);
//   const [carsAwd, setCarsAwd] = useState([]);
//   const [carsIndex, setCarsIndex] = useState([]);
//   const [res, setRes] = useState([]);
//   const [msgResult, setMsgResult] = useState(
//     "Finding recommended cars for you..."
//   );
//   const date1 = new Date(props.before.date);
//   const date2 = new Date(props.before.return_date);
//   const iso1 = date1.toISOString();
//   const iso2 = date2.toISOString();

//   const getCars = () => {
//     Axios.get("https://backend.avis-id.com/public/api/booking/car")
//       .then(function (response) {
//         return response.data;
//       })
//       .then(function (data) {
//         setCars(data);
//       })
//       .catch(function (error) {
//         console.log(error);
//       })
//       .finally(function () {
//         // always executed
//       });
//   };

//   useEffect(() => {
//     getToken();
//     const token = getToken();
//     Axios.get(
//       "https://stage.abgapiservices.com/cars/catalog/v2/vehicles?brand=Budget&pickup_date=" +
//       iso1.split(".", 1)[0] +
//       "&pickup_location=" +
//       props.before.location +
//       "&membership_code=" +
//       props.before.wizard_number +
//       "&dropoff_date=" +
//       iso2.split(".", 1)[0] +
//       "&dropoff_location=" +
//       props.before.return_location +
//       "&country_code=" +
//       props.before.country +
//       "&discount_code=" +
//       props.before.awd_number +
//       "&coupon_code=" +
//       props.before.coupon_code +
//       "&transaction_id&member_code",
//       {
//         headers: {
//           Accept: "*/*",
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + localStorage.getItem("bebe"),
//           client_id: "04e19f27",
//         },
//       }
//     )
//       .then(function (response) {
//         // handle success
//         // console.log(response.data);
//         return response.data;
//       })
//       .then(function (data) {
//         // handle success
//         console.log("NO AWD LENGTH : " + data.vehicles.length);
//         setCars2(data.vehicles);
//         setRes(data.reservation);
//         // set push all index to carsIndex
//         for (let i = 0; i < data.vehicles.length; i++) {
//           setCarsIndex((carsIndex) => [
//             ...carsIndex,
//             { index: i, isPreviewFeature: true },
//           ]);
//         }

//         const findCountry = avisInlusive.find(
//           (item) => item.country_code == props.before.countryPick
//         );

//         console.log("FIND COUNTRY: ", findCountry);

//         const ifReturnDateAndDroffDateIs1DaysBetween3days = (
//           returnDate,
//           dropoffDate
//         ) => {
//           const date1 = new Date(returnDate);
//           const date2 = new Date(dropoffDate);
//           const diffTime = Math.abs(date2 - date1);
//           const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//           return diffDays;
//         };
//         console.log("FIND COUNTRY: ", findCountry);
//         const dateDiff = ifReturnDateAndDroffDateIs1DaysBetween3days(
//           props.before.date,
//           props.before.return_date
//         );

//         if (
//           findCountry != undefined &&
//           dateDiff >= 1 &&
//           dateDiff <= 13 &&
//           props.before.awd_number == ""
//         ) {
//           // I017600
//           Axios.get(
//             "https://stage.abgapiservices.com/cars/catalog/v2/vehicles?brand=Budget&pickup_date=" +
//             iso1.split(".", 1)[0] +
//             "&pickup_location=" +
//             props.before.location +
//             "&membership_code=" +
//             props.before.wizard_number +
//             "&dropoff_date=" +
//             iso2.split(".", 1)[0] +
//             "&dropoff_location=" +
//             props.before.return_location +
//             "&country_code=" +
//             props.before.country +
//             "&discount_code=I017600" +
//             "&coupon_code=" +
//             props.before.coupon_code +
//             "&transaction_id&member_code",
//             {
//               headers: {
//                 Accept: "*/*",
//                 "Content-Type": "application/json",
//                 Authorization: "Bearer " + localStorage.getItem("bebe"),
//                 client_id: "04e19f27",
//               },
//             }
//           )
//             .then(function (response2) {
//               // handle success
//               // console.log(response2.data);
//               return response2.data;
//             })
//             .then(function (data2) {
//               // handle success
//               console.log("AWD LENGTH : " + data2.vehicles.length);
//               setCarsAwd(data2.vehicles);
//               // console.log(cars2);
//             })
//             .catch(function (error2) {
//               // handle error
//               console.log("no awd");
//             });
//         } else if (
//           findCountry != undefined &&
//           dateDiff >= 14 &&
//           dateDiff <= 27 &&
//           props.before.awd_number == ""
//         ) {
//           // I068100
//           Axios.get(
//             "https://stage.abgapiservices.com/cars/catalog/v2/vehicles?brand=Budget&pickup_date=" +
//             iso1.split(".", 1)[0] +
//             "&pickup_location=" +
//             props.before.location +
//             "&membership_code=" +
//             props.before.wizard_number +
//             "&dropoff_date=" +
//             iso2.split(".", 1)[0] +
//             "&dropoff_location=" +
//             props.before.return_location +
//             "&country_code=" +
//             props.before.country +
//             "&discount_code=I068100" +
//             "&coupon_code=" +
//             props.before.coupon_code +
//             "&transaction_id&member_code",
//             {
//               headers: {
//                 Accept: "*/*",
//                 "Content-Type": "application/json",
//                 Authorization: "Bearer " + localStorage.getItem("bebe"),
//                 client_id: "04e19f27",
//               },
//             }
//           )
//             .then(function (response2) {
//               // handle success
//               // console.log(response2.data);
//               return response2.data;
//             })
//             .then(function (data2) {
//               // handle success
//               console.log("AWD LENGTH : " + data2.vehicles.length);
//               setCarsAwd(data2.vehicles);
//               // console.log(cars2);
//             })
//             .catch(function (error2) {
//               // handle error
//               console.log("no awd");
//             });
//         } else {
//           console.log("NO AVIS INCLUSIVE FOR THIS COUNTRY");
//         }
//       })
//       .catch(function (error) {
//         // handle error
//         setMsgResult("No cars available for your search criteria.");
//         if (error.response.status == 401) {
//           window.location.reload();
//         } else if (error.response.status == 400) {
//           setMsgResult(error.response.data.status.errors[0].details);
//         }
//       })
//       .finally(function () {
//         // always executed
//       });
//   }, []);
//   return (
//     <CCN
//       {...props}
//       navigate={navigate}
//       cars={cars}
//       price={price}
//       specs={specs}
//       cars2={cars2}
//       res={res}
//       msgResult={msgResult}
//       carsAwd={carsAwd}
//       carsIndex={carsIndex}
//       iso1={iso1}
//       iso2={iso2}
//     />
//   );
// }
// export default CC;
