import React from "react";

const Header = (props) => {
  return (
    <div className="container py-5">
      <h1>{props.title}</h1>
      <p className="yellow">{props.subtitle}</p>
    </div>
  );
};

export default Header;
