import React from "react";
import TermConditions from "./TermConditions";

const Benefits = () => {
  return (
    <div className="container py-5">
      <h3>AARP Member Benefits</h3>
      <p className="text-dark mt-3">
        <b>
          AARP members can enjoy savings of up to 30% off Budget base rates{" "}
        </b>
        when renting a vehicle from a participating location in the contiguous
        U.S. and Canada. Use Budget Customer Discount (BCD) number{" "}
        <b>Y508501</b> to shop the lowest rates for your next rental.
      </p>
      <p className="text-dark mt-3">
        <b>Plus, enjoy these additional exclusive benefits:</b>
      </p>
      <ul className="similarCarOpt text-dark ps-4">
        <li>
          <b>
            Earn a 3% credit to apply on your next rental. See terms and
            conditions.
          </b>
        </li>
        <li>
          Free upgrade*, Garmin GPS for $6.99/day and unlimited mileage on most
          rentals.
        </li>
        <li>One Additional Driver Fee waived.</li>
        <li>
          Most popular protection selected. As an AARP member, if your car is
          damaged, you are only responsible for up to $5,000 in out-of-pocket
          costs. By purchasing LDW, you won’t pay a thing if damage occurs.**
        </li>
        <li>
          Budget provides primary liability coverage when traveling in the U.S.:
          up to $25,000 per person, to a maximum of $50,000 per accident, plus
          $10,000 for property damages at participating location.
        </li>
      </ul>
      <p className="notes mt-4 text-dark">
        *One car class upgrade subject to availability on compact through
        full-size four-door car booking.
      </p>
      <p className="notes text-dark">
        **Review your personal auto insurance policy and/or your credit card
        benefits to determine if your coverage extends to the operation of a
        rental vehicle. If it does not, or you do not have personal auto
        insurance, the additional LDW coverage can be purchased from Budget at
        the time of rental.
      </p>
      <p className="notes mt-2 text-dark">
        AARP member benefits are provided by third parties who pay royalty fees
        to AARP for the use of its intellectual property. These fees are used
        for the general purposes of AARP. Please contact the provider directly
        for details.
      </p>
      <TermConditions />
    </div>
  );
};

export default Benefits;
