import React from "react";
import { Link } from "react-router-dom";

export default class PS extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/productservice.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
      cursor: "pointer",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
      cursor: "pointer",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
      cursor: "pointer",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Budget Car Rental Products</h1>
                <p style={myLink}>
                  Save money, time and worry with Budget's helpful products
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-12">
                <p style={myPara}>
                  A great deal on car rental isn't all you can expect from
                  Budget. That's because you'll also get an easier, more
                  convenient car rental experience thanks to Budget's smart
                  services. Get products and perks either when you pick up your
                  car, or simply select them when you{" "}
                  <a href="#" style={myLink}>
                    make a car rental reservation online.
                  </a>
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={"/van-rental"} className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Travel Tab Adventurer (US/CA only)
                    </label>
                    <label style={myParas}>
                      Explore the range of car rental products Budget offers to
                      enhance your experience.
                    </label>
                    <label style={myLink}>Learn More </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={"/gps-navigation"} className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      GPS Navigation
                    </label>
                    <label style={myParas}>
                      Explore the range of car rental products Budget offers to
                      enhance your experience.
                    </label>
                    <label style={myLink}>Learn More </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={"/productservice/products/child-safety-seats"}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Child Seats
                    </label>
                    <label style={myParas}>
                      Explore the range of car rental products Budget offers to
                      enhance your experience.
                    </label>
                    <label style={myLink}>Learn More </label>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
