import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="container py-4">
      <h1 className="mb-5">Travel Agents Contact Us</h1>
      <h4 className="mb-3">
        <Link className="linked-blue">GDS Support</Link>
      </h4>
      <div className="row mb-2">
        <div className="col-md-4">
          <Link className="linked">travelhelpdesk@avisbudget.com</Link>
        </div>
        <div className="col-md-3">800-HELP-100</div>
        <div className="col-md-5">
          Hours of operation are from 07.30 - 17.00
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-4">Reservation Center</div>
        <div className="col-md-8">800-527-0700</div>
      </div>
      <h4 className="mb-3">Commission Policy</h4>
      <p>
        For commission inquiries please email -{" "}
        <Link className="linked">travelhelpdesk@avisbudget.com</Link>
      </p>
    </div>
  );
};

export default Contact;
