import React from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import SignModal from "./SignModal";

export default class HeaderThree extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <header
        class="main-header main-header-one clearfix"
        style={{
          background: "#fff",
          position: "sticky",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "99",
        }}
      >
        <div class="main-header-one__wrapper">
          <div class="main-header-one__bottom">
            <nav class="main-menu main-menu--1">
              <div class="main-menu__wrapper">
                <div class="container py-4">
                  <div class="main-menu__wrapper-inner">
                    <div class="main-header-one__bottom-left">
                      <div class="logo">
                        <Link to={process.env.PUBLIC_URL + `/`}>
                          <img
                            src={publicUrl + "assets/images/icon/budget.png"}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>

                    <div class="main-header-one__bottom-right">
                      <Link className="yellow">
                        <SignModal />
                      </Link>
                      <img
                        src={publicUrl + "assets/cars-budget/aarp-member.webp"}
                      />

                      {/* <div class="main-header-one__bottom-right-search">
                                                    <SearchButton />
                                                </div> */}

                      {/* <LanguageSwitcher /> */}

                      {/* <div class="main-header-one__bottom-right-btn">
                                                    <Link to={process.env.PUBLIC_URL + `/`} class="thm-btn">+ Add Listing </Link>
                                                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}
