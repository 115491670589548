import React from "react";
import { Link } from "react-router-dom";

const AffiliateProgram = () => {
  return (
    <div className="container py-4">
      <h1 className="text-center">Budget Rental Car Affiliate Program</h1>
      <p className="mt-3">
        You can share in our success! Start generating revenue for your website
        today by partnering with Budget car rental! Budget aims to be the
        world’s best value car rental brand; you can connect visitors to your
        site with over 3,000 rental locations in more than 120 countries by
        joining the Budget Affiliate Program.
      </p>
      <p className="mt-2">
        <b>In Summary: </b>
        Start generating revenue for your web site today by partnering with one
        of the world's premier car rental companies!
      </p>
      <div className="mx-3 py-5">
        <h5>How does the affiliate program work?</h5>
        <p className="mt-2">
          Work with one of the fastest growing rental companies providing
          affordable and reliable car rental. The Budget affiliate program
          offers you access to work with one of the most globally recognized
          travel brands and gives your customers access to excellent value car
          rental rates!
        </p>
        <p className="mt-2">
          We offer leading commission rates of up to 4% with regular incentives
          to boost your earnings on certain cars, locations or driving dates.
        </p>
        <h5 className="mt-5">Affiliate Program Details</h5>
        <p className="mt-2">
          You will get access to a wide range of regularly updated creative,
          links, and brand information to help you successfully promote Budget
          on your website. We currently provide banners in the most popular
          sizes, including; MPU, vertical skyscrapers and horizontal
          leaderboards.
        </p>
        <p className="mt-2">
          Up-to-date tracking ensures that all sales your website drive to
          Budget.com are reliably tracked. You can see up-to-date information
          about how much you have earned with Budget on our affiliate dashboard.
        </p>
        <h5 className="mt-5">How to Join</h5>
        <p className="mt-2">
          If you’re already a publisher on the Partnerize (formerly known as
          Performance Horizon) network then you should contact the email below
          to be guested to the Avis Network.
        </p>
        <p className="mt-2">
          Once invited you just need to log in, navigate to ‘Admin > Campaign’,
          look for ‘Avis US’ and press ‘Join’. You’ll be approved and get access
          to all the new information, offers and creative.
        </p>
        <p className="mt-2">
          If you need to sign up to the Partnerize network,{" "}
          <Link className="linked">click here</Link> to join.
        </p>
        <p className="mt-2">
          Once you have successfully signed up, you will receive an automated
          email confirming your credentials and a link to log into the platform.
          Within the console navigate to ‘Admin > Campaign’ and search for Avis
          select the campaign you wish to join and hit ‘Join’ & accept the T&Cs.
        </p>
        <p>
          Get more information about our travel affiliate program on our{" "}
          <Link className="linked">car rental affiliate common questions</Link>{" "}
          page.
        </p>
        <h2 className="text-dark mt-5">Contact Us</h2>
        <p className="mt-2">
          If you’d like to discuss the affiliate strategy for Budget, how you
          can get more involved in the Budget Affiliate program, or if you have
          any questions about the Partnerize platform, signing up or getting
          setupany opportunities that Budget may be interested in; please
          contact the Budget team at{" "}
          <Link className="linked">ABGAffiliateProgram@avisbudget.com</Link>
        </p>
        <p className="mt-2">
          Please allow two to three days for a response during peak times.
        </p>
      </div>
    </div>
  );
};

export default AffiliateProgram;
