import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import Ser from "../components/service/Ser";
import Title from "../Title";

const Service = () => {
  Title("Services");
  return (
    <>
      <HeaderOne />
      <Ser />
      <FooterOne />
    </>
  );
};

export default Service;
