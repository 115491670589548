import React from "react";
import Card from "../../common/card/Card";
import Header from "../../common/header/Header";

const TravelAgentContent = () => {
  return (
    <>
      <Header
        title="Travel Agent Car Rentals Program"
        subtitle="We appreciate your loyalty!"
      />
      <div className="carsContainer">
        <div className="container py-5">
          <p>
            Budget is pleased to provide you, our valuable travel partners, with
            the latest information you need to service your clients and take
            advantage of our special car rental programs. It's our pleasure to
            offer you Unlimited Rewards. Earn dollar rewards and spend anywhere,
            anytime you want.{" "}
          </p>
          <div className="row py-4">
            <Card
              title="IATA Car Rental Rates and Reservation"
              description="Book rental cars using your IATA number and take advantage of special Budget Car Rental travel agent commissions, great rates, and loyalty programs."
              link="travel-agent/iata-car-rental"
            />
            <Card
              title="Unlimited Rewards"
              description="Earn dollar rewards when you book Budget by joining Unlimited Rewards. You can get up to $7 in rewards with each rental. Get automatic dollar rewards to your own reloadable Unlimited Rewards debit card."
            />
            <Card
              title="GDS Reservation Tools"
              description="Downloadable Global Distribution System (GDS) Reference Cards for car rental reservations at your fingertips, 24/7. From Budget Car Rentals."
              link="travel-agent/gds-reservation-tools"
            />
            <Card
              title="Contact Us"
              description="Need some help? Have a question or comment about the Budget Car Rental travel agents programs and discounts. Contact us here and get the answers you need."
              link="travel-agent/contact-us"
            />
            <Card
              title="Special Travel Agent Rates"
              description="Travel agents in the U.S. and Canada, can receive up to 35% off personal rentals worldwide."
              link="travel-agent/travel-agent-deals"
            />
            <Card
              title="Commissions Policy"
              description="In recognition of the business brought to us by travel agencies, Budget pays 5% commission on the base rate of leisure rentals and commercial rentals where there's no individually established discount program in place. Commission payment applies to non-prepaid rates only."
              link="travel-agent/commissions-policy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelAgentContent;
