import React from "react";
import { Link } from "react-router-dom";

export default class TI extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/tripideas1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "200px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1>Trip Ideas for USA Travel</h1>
                <p>Road Trip Ideas and More</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mb-5 mt-3">
            <div className="row mb-4">
              <div className="col-sm-12">
                <p className="mb-3">
                  Enjoy the open-road with these road trip ideas from all over
                  the USA. Pick a road trip route near you, drive across the
                  nation, or explore somewhere new. When you’re behind the wheel
                  of a car the adventure is yours! Find travel inspiration here
                  for USA travel.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-3">
            <div className="row mt-4">
              <div
                className="col-xl-4 col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="blog-one__single">
                  <div className="blog-one__single-img">
                    <img
                      src={publicUrl + "assets/images/resources/ltr5.webp"}
                      alt=""
                    />
                  </div>

                  <div className="blog-one__single-content">
                    <a href="#">
                      <h5>Excepteur occaecat cupidatat </h5>
                    </a>
                    <p className="my-5" style={myPara}>
                      Take your long-term car rental down California State Route
                      1 and experience scenic beaches, unusual landmarks, and
                      delicious cuisine.{" "}
                    </p>
                    <button
                      className="btn mt-3 w-100"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      View Trip Itinerary
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="blog-one__single">
                  <div className="blog-one__single-img">
                    <img
                      src={
                        publicUrl + "assets/images/resources/trip-pacific.jpg"
                      }
                      alt=""
                    />
                  </div>

                  <div className="blog-one__single-content">
                    <a href="#">
                      <h5>Excepteur occaecat cupidatat </h5>
                    </a>
                    <p className="my-5" style={myPara}>
                      Take your long-term car rental down California State Route
                      1 and experience scenic beaches, unusual landmarks, and
                      delicious cuisine.{" "}
                    </p>
                    <button
                      className="btn mt-3 w-100"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      View Trip Itinerary
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="blog-one__single">
                  <div className="blog-one__single-img">
                    <img
                      src={
                        publicUrl + "assets/images/resources/trip-pacific.jpg"
                      }
                      alt=""
                    />
                  </div>

                  <div className="blog-one__single-content">
                    <a href="#">
                      <h5>Excepteur occaecat cupidatat </h5>
                    </a>
                    <p className="my-5" style={myPara}>
                      Take your long-term car rental down California State Route
                      1 and experience scenic beaches, unusual landmarks, and
                      delicious cuisine.{" "}
                    </p>
                    <button
                      className="btn mt-3 w-100"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      View Trip Itinerary
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="blog-one__single">
                  <div className="blog-one__single-img">
                    <img
                      src={
                        publicUrl + "assets/images/resources/trip-pacific.jpg"
                      }
                      alt=""
                    />
                  </div>

                  <div className="blog-one__single-content">
                    <a href="#">
                      <h5>Excepteur occaecat cupidatat </h5>
                    </a>
                    <p className="my-5" style={myPara}>
                      Take your long-term car rental down California State Route
                      1 and experience scenic beaches, unusual landmarks, and
                      delicious cuisine.{" "}
                    </p>
                    <button
                      className="btn mt-3 w-100"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      View Trip Itinerary
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="blog-one__single">
                  <div className="blog-one__single-img">
                    <img
                      src={
                        publicUrl + "assets/images/resources/trip-pacific.jpg"
                      }
                      alt=""
                    />
                  </div>

                  <div className="blog-one__single-content">
                    <a href="#">
                      <h5>Excepteur occaecat cupidatat </h5>
                    </a>
                    <p className="my-5" style={myPara}>
                      Take your long-term car rental down California State Route
                      1 and experience scenic beaches, unusual landmarks, and
                      delicious cuisine.{" "}
                    </p>
                    <button
                      className="btn mt-3 w-100"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      View Trip Itinerary
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
