import React from "react";
import FooterThree from "../common/footer/FooterThree";
import HeaderThree from "../common/header/HeaderThree";
import Benefits from "../components/members/Benefits";
import BestDeals from "../components/members/BestDeals";
import CardMembers from "../components/members/CardMembers";
import LandingSelectCar from "../components/road/LandingSelectCar";

const AARPMembers = () => {
  return (
    <>
      <HeaderThree />
      <LandingSelectCar
        image="banner-aarp.webp"
        title="Get the Budget Military Discount - Up to 25% Off with Veterans Advantage"
        sub="Plus earn 3% credit on your next rental."
        page="aarp"
      />
      <BestDeals />
      <Benefits />
      <div className="bg-orange py-5">
        <div className="container">
          <div className="row">
            <CardMembers
              image="card-member1"
              title="Save up to 35% with Pay Now"
              subtitle="Ready to take your next vacation? Plan it now and save up to 35% on your car rental reservation from Budget when you select Pay Now."
            />
            <CardMembers
              image="card-member2"
              title="Take $10 off base rates"
              subtitle="Receive $10 off the base rate when you spend $175 or more on your next car rental."
            />
            <CardMembers
              image="card-member3"
              title="Enroll in Budget Fastbreak"
              subtitle="Want a quick exit from the rental counter? Join Fastbreak. It's smart, simple and fast."
            />
            <CardMembers
              image="card-member4"
              title="Save on long term rentals"
              subtitle="Enjoy 10% off base rates on rentals of 28 consecutive days or more at participating locations in the U.S. and Canada."
            />
            <CardMembers
              image="card-member5"
              title="Not an AARP Member?"
              subtitle="Join now for exclusive rental benefits from Budget."
            />
            <CardMembers
              image="card-member6"
              title="Big move? Big savings."
              subtitle="AARP members can save up to 20% off base rates on Budget Truck rentals."
            />
          </div>
        </div>
      </div>
      <FooterThree />
    </>
  );
};

export default AARPMembers;
