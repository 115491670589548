import React from 'react';
import {Link} from 'react-router-dom';

export default class DealsThree extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/';
        const myStyle = {
            backgroundImage: "url("+publicUrl+"assets/images/resources/deals-1.webp)",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
        const mySection = {
            backgroundColor:"rgb(247, 139, 0)",
        }
        const myIsi = {
            maxWidth:"30%",
            margin:"auto"
        }
        const myNav = {
            backgroundColor:"rgb(237, 85, 5)",
        }
        return (
            <>
                {/* Start Slogan One */}
                <section style={mySection}>
                    <div className='container'>
                        <a href='#' className='row' style={myStyle}>
                            <div className='col-xl-12 text-center pt-5 pb-5' style={myIsi}>
                                <img src={publicUrl+'assets/images/resources/deals-2.avif'} className='img-fluid' />
                                <button className="thm-btn comment-form__btn mt-2 p-2">Get This Deal</button>
                            </div>
                        </a>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-12 px-0 py-3'>
                                <h3 className='text-white'>Save Big with the Best Car Rental Deals</h3>
                            </div>
                        </div>
                    </div>
                    <div className='container pb-5'>
                        <div className='row'>
                            <div className='col-xl-12 px-0 py-3'>
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a className="nav-link text-white" href={process.env.PUBLIC_URL + `/deals`}>US Offers</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-white" href={process.env.PUBLIC_URL + `/international`}>International</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-white active" style={myNav} href="#">Partner Offers</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-12 px-0'>
                                <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="blog-one__single">
                                        <div className="blog-one__single-img">
                                            <img src={publicUrl+"assets/images/resources/deals-2.avif"} alt="" />
                                        </div>

                                        <div className="blog-one__single-content">
                                            <p className="blog-one__single-content-tagline">Excepteur occaecat cupidatat </p>
                                            {/* <h2><Link to={process.env.PUBLIC_URL + `/blog-details`}>Besting and commercial We <br /> with This Blog.</Link></h2> */}
                                            {/* <div className="line"></div> */}
                                            <div className="text">
                                                <p>There are many variations of passages off ran of lorem Ipsum available.. </p>
                                            </div>
                                            <button className="btn btn-primary w-100">Save Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Slogan One */}
            </>
        )
    }
}