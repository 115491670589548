import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Contact from "../components/travel-agent/Contact";

const TravelAgentContact = () => {
  const breadcrumb = ["Programs", "Travel Agent Program", "Contact Us"];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Contact />
      <FooterOne />
    </>
  );
};

export default TravelAgentContact;
