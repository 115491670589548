import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const Nav = (props) => {
  const [btnMobile, setBtnMobile] = useState(false);

  useEffect(() => {
    props.setIsMenu(btnMobile);
  }, [btnMobile]);

  return (
    <>
      <div className="main-menu__inner">
        <MobileMenu btnIsMobile={btnMobile} setIsMobile={setBtnMobile} />
        <ul className="main-menu__list">
          <li className="dropdown">
            <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/`}>Reservations</Link>
            <ul>
              <li>
                <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/makereservation`}>
                  Make a Reservation
                </Link>
              </li>
              <li>
                <Link style={{ fontSize: "17px" }} to={`${process.env.PUBLIC_URL}/view-cancel`}>
                  View / Cancellation
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/`}>Events and Specials </Link>
            <ul>
              <li>
                <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/international`}>
                  Promotion
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/carguides`}>Car Guides</Link>
          </li>
          <li className="dropdown">
            <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `#`}>Products and Services</Link>
            <ul>
              <li>
                <Link style={{ fontSize: "17px" }} to={`${process.env.PUBLIC_URL}/productservice/products`}>
                  Products
                </Link>
              </li>
              <li>
                <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/productservice/services`}>
                  Services
                </Link>
              </li>
              <li>
                <Link style={{ fontSize: "17px" }}
                  to={process.env.PUBLIC_URL + `/productservice/protection`}
                >
                  Protections and Coverages
                </Link>
              </li>
              <li>
                <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/productservice/program`}>
                  Programs
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `#`}>Locations </Link>
            <ul>
              <li>
                <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/findlocation`}>
                  Find a Location
                </Link>
              </li>
              <li>
                <Link style={{ fontSize: "17px" }} to={process.env.PUBLIC_URL + `/airportlocation`}>
                  Top Airport Location
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Nav;
