import React from "react";
import { Link } from "react-router-dom";

const AirlinesContent = () => {
  return (
    <div className="container py-4">
      <h1 className="mb-3">Airlines</h1>
      <p className="yellow mb-3">Wing your way to travel rewards.</p>
      <p className="mb-3">
        <b>How it works</b>
      </p>
      <p className="mb-3">
        If you’re a RapidRez Member with{" "}
        <Link className="linked text-decoration-none">Fastbreak</Link>, just add
        your frequent flyer number when you reserve on budget.com – and start
        earning airline miles, points or credits. Always use your RapidRez
        Number for the reservation, and then enter your program information on
        Step 4. Or just stop at the counter and provide your frequent flyer
        number at participating airport locations. A Fastbreak/RapidRez Number
        is not required to earn frequent flyer miles. Frequent flyer mileage
        taxes and surcharges apply.
      </p>
      <div className="row">
        <div className="col-md-4">
          <div className="d-flex flex-column">
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aer-lingus`}
              >
                Aer Lingus AerClub
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aeroplan`}
              >
                Air Canada - Aeroplan
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aero-new-zealand`}
              >
                Air New Zealand Air Points
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aero-alaska`}
              >
                Alaska Airlines Mileage Plan
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aero-american`}
              >
                American Airlines AAdvantage
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aero-life-miles`}
              >
                Avianca-Taca LifeMiles
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/airasia`}
              >
                BIG Loyalty
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/british-airways`}
              >
                British Airways Executive Club
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/cathay-pacific`}
              >
                Cathay Pacific
              </Link>
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-column">
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aero-copa`}
              >
                Copa Airlines ConnectMiles
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aero-el-al`}
              >
                El Al Frequent Flyer Club
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/emirates`}
              >
                Emirates Skywards
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/frontier`}
              >
                FRONTIER Miles
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/aero-hawaiian`}
              >
                Hawaiian HawaiianMiles
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/iberia-plus`}
              >
                Iberia Plus
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/japan-airlines`}
              >
                JAL Mileage Bank
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/jet-blue`}
              >
                JetBlue TrueBlue
              </Link>
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-column">
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/malaysia-airlines`}
              >
                Malaysia Airlines Enrich
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/qatar`}
              >
                Qatar Airways Privilege Club
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/saudia`}
              >
                Saudi Arabian Alfursan
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/singapore-airlines`}
              >
                Singapore Airlines KrisFlyer
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/spirit`}
              >
                Spirit Airlines FREE SPIRIT
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/south-west`}
              >
                Southwest Rapid Rewards
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/thai`}
              >
                Thai Airways Royal Orchid Plus
              </Link>
            </p>
            <p className="mb-2">
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/united-airlines`}
              >
                United MileagePlus
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirlinesContent;
