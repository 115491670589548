import React from "react";
import { Link } from "react-router-dom";

const Box = (props) => {
  return (
    <div className="col-lg-2 col-md-3 mb-3">
      <Link>
        <div
          className={`box ${props.class} py-3 d-flex flex-column justify-content-between`}
        >
          <div className="px-2">
            <p>
              <b>{props.title}</b>
            </p>
            <p>{props.subtitle}</p>
          </div>
          <button>Save Now</button>
        </div>
      </Link>
    </div>
  );
};

export default Box;
