import React from "react";

const Discount = () => {
  return (
    <div className="container py-4">
      <h1 className="mb-5">Travel Agent Discount</h1>
      <p className="mb-3">
        Budget offers travel agents up to 20% off in the contiguous U.S., HI and
        Canada. Rates include unlimited mileage on most cars and are available
        on most car groups for leisure products. Plus, U.S. and Canadian travel
        agents will get up to 35% savings for rentals in Mexico, the Caribbean,
        Latin America, Europe, Africa, Asia, Australia, New Zealand.{" "}
      </p>
      <p className="mb-3">
        We're offering these special discounts because we value your efforts and
        once you experience the value, ease and convenience of renting with
        Budget, we know you'll want to book Budget cars for your clients.
      </p>
      <p className="mb-3">
        To be eligible for the discount you must present one of the following
        cards as employment identification:
      </p>
      <ul className="similarCarOpt ps-5 mb-3">
        <li>American Society of Travel Agents Card (ASTA)</li>
        <li>International Airlines Travel Agent Network Card (IATAN)</li>
        <li>International Air Transport Association Card (IATA)</li>
        <li>Cruise Lines International Association Card (CLIA)</li>
      </ul>
      <p className="mb-3">
        To receive the discount, the card presented must be printed with the
        same name as the renter and must be used in conjunction with the
        following Budget BCD discount numbers in the /CD-Field of the
        reservation.
      </p>
      <table
        cellpadding="1"
        cellspacing="0"
        border="0"
        width="75%"
        className="mb-5"
      >
        <tbody>
          <tr>
            <th scope="col">In GDS:</th>
            <th scope="col">&nbsp;</th>
            <th scope="col">On Budget.com</th>
            <th scope="col">&nbsp;</th>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>For U.S. Travel Agents:</td>
            <td>/CD-T997100</td>
            <td>For U.S. Travel Agents:</td>
            <td>T997000</td>
          </tr>
          <tr>
            <td>For Canadian Travel Agents:</td>
            <td>/CD-T997100</td>
            <td>For Canadian Travel Agents:</td>
            <td>T997000</td>
          </tr>
        </tbody>
      </table>
      <ul className="similarCarOpt ps-5 mb-3">
        <li>Unlimited mileage is included on most rentals.</li>
        <li>Limited availability.</li>
        <li>Non-commissionable.</li>
        <li>Car rental return restrictions may apply.</li>
        <li>An advance reservation is required.</li>
        <li>Vehicles are subject to availability.</li>
        <li>This offer may not apply to all rates at all times.</li>
        <li>
          Taxes, surcharges, vehicle licensing fee (up to $1.98/day in HI),
          customer facility charges ($10/contract in CA), concession recovery
          fees (may be avoided at some locations by not using our shuttle bus or
          not renting within 24-hours of flight arrival) may apply and are
          extra.
        </li>
        <li>
          Optional items such as LDW ($26.99/day or less) additional driver fee
          and refueling fee are extra.
        </li>
        <li>
          See your estimated total on your reservation for list of applicable
          charges.
        </li>
        <li>
          Check your automobile insurance policy for rental vehicle coverage.
        </li>
        <li>Voucher pre-payments cannot be accepted for this offer.</li>
        <li>
          The minimum age requirement for travel agents using this discount
          number is 25, but may vary by location. Under age surcharges may
          apply.
        </li>
        <li>
          SATO employees are not required to present an IATAN or IATA card, but
          must present their SATO employee I.D. card printed with the same name
          as the renter.
        </li>
        <li>
          Because the identification is for discount purposes only the renter
          must present a Budget-honored charge card as credit identification.
        </li>
        <li>
          The discount is applicable only to time and mileage portion of the
          rental.
        </li>
        <li>
          A frequent flyer tax recovery surcharge may be imposed on certain
          rentals earning miles or points.
        </li>
        <li>Renter must meet Budget driver age and credit requirements.</li>
      </ul>
    </div>
  );
};

export default Discount;
