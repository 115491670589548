import React from "react";
import { Link } from "react-router-dom";

const CardMembers = (props) => {
  return (
    <div className="col-md-4 mb-4">
      <Link>
        <div className="card-members" style={{ background: "white" }}>
          <div
            style={{
              height: "294px",
              width: "100%",
              background: `url(${process.env.PUBLIC_URL}/assets/cars-budget/${props.image}.webp)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div
            className="members-description p-3 text-dark d-flex flex-column justify-content-between"
            style={{ height: "350px" }}
          >
            <div className="pt-3">
              <p>
                <b>{props.title}</b>
              </p>
              <p className="mt-2">{props.subtitle}</p>
            </div>
            <div className="pt-2">
              <button className="btnCar" style={{ width: "100%" }}>
                Book Now
              </button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CardMembers;
