import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import CollapseItemFour from "./CollapseItemFour";
import { Link } from "react-router-dom";

const CollapseItemAbout = ({ label, text }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="row">
      <button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="text-start mb-3"
      >
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/icon/arrow.png`}
          alt="arrow"
          width={10}
          className={`expand ${open ? "active" : ""} me-1`}
        />
        <span className="text-expand">{label}</span>
      </button>
      <Collapse in={open}>
        <p className="mb-3 ms-4">{text}</p>
      </Collapse>
    </div>
  );
};

export default CollapseItemAbout;
