import React from "react";
import { Link } from "react-router-dom";

const Commissions = () => {
  return (
    <div className="container py-4">
      <h1 className="mb-5">Commissions Policy</h1>
      <p className="mb-3">
        In recognition of the business brought to us by travel agencies, Budget
        pays 5% commission on the base rate of leisure rentals and commercial
        rentals where there's no individually established discount program in
        place. Commission payment applies to non-prepaid rates only.
      </p>
      <p className="mb-4">
        Budget's policy is to pay commission on the base rate (also known as
        'time and mileage') reserved by a travel professional for a car rental,
        unless the renter actually incurs fewer base rental charges than
        reserved. In that instance. Budget pays commission on the base rate
        actually incurred by the renter. Budget does not pay commission on
        rental charges other than reserved 'time and mileage' charges,
        including, but not limited to, all optional charges incurred beyond the
        reserved base rates, Garmin GPS System, additional driver fees, Loss
        Damage Waiver, Personal Accident and Effects Insurance, Emergency
        Sickness Plan, and Supplemental Liability Insurance, ski racks, child
        safety seats, fuel recovery service fees, airport concession recovery
        fees, other surcharges and taxes, or any other fee or charge besides the
        base rate.
      </p>
      <p className="text-dark mb-2">
        <b>Commission Inquiries</b>
      </p>
      <p className="mb-3">
        Budget knows how important your commission is to you, therefore
        commissions are processed promptly after the completion of each
        qualifying rental and payments are disbursed monthly.
      </p>
      <p className="mb-5">
        If you have not received your commission within a 60-day period after
        completion of a rental, please send an email to{" "}
        <Link className="linked">travelhelpdesk@avisbudget.com</Link>. We will
        investigate your inquiry and respond back to you within 7 business days.
        Forms can also be found in your GDS under Budget keyword "COMM".
      </p>
      <p className="text-dark mb-3">
        <b>ARC/IATA Numbers</b>
      </p>
      <p className="mb-3">
        Budget must have your agency's ARC/IATA number on file and in your
        reservation for you to receive your commission. We regularly update our
        files with current ARC/IATA numbers.
      </p>
      <p className="mb-3">
        To confirm that your ARC/IATA number is correctly in our files or to
        apply for a temporary Budget assigned number all you have to do is
        contact Budget at:
      </p>
      <p className="mb-3">1-800-HELP-100 in the U.S.</p>
      <p className="mb-3">1-800-435-7004 in Canada</p>
      <p className="mb-3">
        For a Budget assigned number provide us with the following information;
        Agency Name/Address/Phone, IRS Taxpayer Number, Business License Number,
        and Owner Name.
      </p>
      <p className="mb-3">
        Budget will then verify the above information and call you back within
        48 hours with a budget assigned number. You may begin using this Budget
        assigned number immediately to begin tracking commissions. We will send
        you an official application to complete, sign and return to Budget. As
        soon as your agency application is approved, payment of all tracked
        commissions will be made to your agency.
      </p>
    </div>
  );
};

export default Commissions;
