import React from "react";
import { Link } from "react-router-dom";
import FooterOne from "../../common/footer/FooterOne";
import HeaderOne from "../../common/header/HeaderOne";
import BreadcrumbCar from "../luxury/BreadcrumbCar";

const Rewards = () => {
  const breadcrumb = [
    "Programs",
    "Miles, Points & Partners",
    "Hotel",
    "Best Western Rewards",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <div className="container py-4">
        <h1>Best Western Rewards</h1>
        <div className="py-4 text-dark" style={{ fontSize: "15px" }}>
          <p>
            <b>Program Reward</b>
          </p>
          <p className="mt-3">
            Earn 1,000 Best Western Rewards points per rental on all qualifying
            rentals. Plus, receive valuable discounts whenever you provide your
            Best Western Rewards / Budget Customer Discount (BCD) number{" "}
            <b>U187000</b>.
          </p>
          <p className="mt-3">
            <b>Terms and Conditions:</b>
          </p>
          <ul className="similarCarOpt ps-4 mt-3">
            <li>
              Valid at participating Budget airport and neighborhood locations
              worldwide
            </li>
            <li>Hotel stay is not required to earn points</li>
            <li>
              Members must provide their Best Western Rewards membership number
              at the time of rental
            </li>
            <li>
              Points may not be earned on free, tour or certain travel industry
              rates and may only be earned in one frequent traveler program per
              rental.
            </li>
          </ul>
          <p className="mt-3">
            <b>Frequent Traveler Tax </b>
          </p>
          <p className="mt-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with points earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent traveler points from the following U.S. airline and hotel
            programs, Budget will assess a frequent traveler surcharge
            equivalent to USD $0.75 per day (except $1.00 per day for American
            Airlines, Copa Airlines, JetBlue Airways and Southwest Airlines).
            The amount assessed is collected by Avis to offset a portion of
            Budget's annual cost of participation in frequent traveler programs.
          </p>
          <ul className="similarCarOpt ps-4 mt-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>Frontier Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>IHG - InterContinental Hotel Group</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotel Group</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
          <p className="mt-3">
            <b>Partner Information</b>
          </p>
          <p className="mt-3">
            For more information or to enroll in the Best Western Rewards
            program, please use the information below.
          </p>
          <table
            border="0"
            cellpadding="2"
            cellspacing="3"
            className="mt-3"
            style={{ width: "60%" }}
          >
            <tbody>
              <tr>
                <td valign="top">Mailing Address</td>
                <td valign="top">
                  Best Western Rewards <br /> International <br /> P.O. Box
                  42007 <br /> Phoenix, AZ 85080-2007
                </td>
              </tr>
              <tr>
                <td valign="top" style={{ width: "34%" }}>
                  Phone Number:
                </td>
                <td valign="top" style={{ width: "66%" }}>
                  1-800-237-8483
                </td>
              </tr>
              <tr>
                <td valign="top" style={{ width: "34%" }}>
                  Web Site:
                </td>
                <td valign="top" style={{ width: "66%" }}>
                  <Link to={"www.bestwestern.com"} className="linked">
                    www.bestwestern.com
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default Rewards;
