import React from "react";
import { Link } from "react-router-dom";

const Reservations = () => {
  return (
    <div className="container py-4">
      <h1>Travel Agent GDS Tools for Car Rental</h1>
      <p className="mt-5 similar">
        <b>
          Travel agents, Budget.com is your handy resource for Amadeus, Galileo,
          Sabre and Worldspan!
        </b>
      </p>
      <p className="mb-3">
        Need quick access to GDS Reference Cards for rental cars? Look no
        further!
      </p>
      <hr />
      <h4 className="mb-3">GDS Reference Card</h4>
      <p className="mb-4">
        We are at your service with Global Distribution System (GDS) Reference
        Cards for Worldspan, Sabre, Galileo and Amadeus.
      </p>
      <table class="table">
        <thead>
          <tr style={{ background: "#f1f1f1", border: "inherit" }}>
            <th style={{ border: "inherit" }} className="similar">
              GDS
            </th>
            <th style={{ border: "inherit" }} className="similar">
              PDF Files
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Amadeus</td>
            <td>
              <Link className="linked text-decoration-none">View/Print</Link>
            </td>
          </tr>
          <tr>
            <td>Galileo</td>
            <td>
              <Link className="linked text-decoration-none">View/Print</Link>
            </td>
          </tr>
          <tr>
            <td>Sabre</td>
            <td>
              <Link className="linked text-decoration-none">View/Print</Link>
            </td>
          </tr>
          <tr>
            <td>Worldspan</td>
            <td>
              <Link className="linked text-decoration-none">View/Print</Link>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="mb-3 mt-4">
        To View PDF files, you must have Adobe Reader installed on your
        computer.
      </p>
      <Link className="linked text-decoration-none">
        Download a free version from Adobe's Web site.
      </Link>
      <hr />
      <h4 className="mb-5">GDS Support</h4>
      <p className="mb-2">
        <b className="text-dark">Email: </b>{" "}
        <Link className="linked text-decoration-none">
          gds.support@avisbudget.com
        </Link>{" "}
      </p>
      <p className="mb-2">
        <b className="text-dark">Phone: </b> 1-800-HELP-100
      </p>
      <p className="mb-2">
        <b className="text-dark">Hours of Operation: </b> 7:30AM- 5:00PM CST
      </p>
    </div>
  );
};

export default Reservations;
