import React from "react";
import { Link } from "react-router-dom";

export default class AL extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/tripideas1.webp)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      backgroundColor: "#f78b00",
      height: "200px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#fff",
    };
    const myBorder = {
      borderBottom: "2px solid #eaeaea",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-4">
            <div className="row">
              <div className="col-sm-12">
                <h3>Airport Car Rental Through Budget</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <Link
                      to={"/findlocation"}
                      className="breadcrumb-item myLink"
                      aria-current="page"
                    >
                      Back to Locations
                    </Link>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-4">
            <div className="row" style={myBorder}>
              <div className="col-sm-12">
                <h3 style={{ color: "#000" }}>Budget Airport Car Rentals</h3>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-4">
            <div className="row mt-3">
              <div className="col-sm-12">
                <h5 style={{ color: "#000" }}>
                  <b>USA</b>
                </h5>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myBorder}>
                <h5 style={{ color: "#000" }}>Alaska</h5>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Albania</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Albania</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Albania</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Albania</a>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myBorder}>
                <h5 style={{ color: "#000" }}>Albama</h5>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Bahamas</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Bahamas</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Bahamas</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Bahamas</a>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-one">
          <div className="container py-3">
            <div className="row">
              <div className="col-sm-12">
                <h4 className="text-white">Tips for Airport Car Rental</h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <p>
                  You’ve landed and picked up your luggage, but where to next?
                  Follow the signs to the airport car rental counters. Depending
                  on the airport, it’s typically near the baggage claim area.
                  After you have found the counter, you’ll check-in, sign
                  paperwork, and grab the keys. Before you know it, you are off
                  to your next destination.{" "}
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <p>
                  Renting a car can sometimes be intimidating, but with these
                  six tips and tricks from Budget, you’ll be on the road in an
                  airport car rental in no time!
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <ol>
                  <li className="mb-3">
                    <b>+ Download the Budget App:</b> With the Budget App you
                    can update your reservations and check in on your rental
                    status before pick up.{" "}
                  </li>
                  <li className="mb-3">
                    <b>+ Map out where you want to go:</b> Before you arrive at
                    the airport, see how far your next location is and if there
                    are any specific places you want to stop along the way.{" "}
                  </li>
                  <li className="mb-3">
                    <b>+ Look for a gas station when leaving:</b> To save money,
                    you’ll want to fill up the tank before returning your
                    airport car rental. When leaving the airport, look for
                    nearby gas stations.{" "}
                  </li>
                  <li className="mb-3">
                    <b>+ Pick a car you’re comfortable with:</b> You are already
                    going to be driving around a new city, so instead of renting
                    the cheapest car, consider renting a car similar to the one
                    you drive at home.{" "}
                  </li>
                  <li className="mb-3">
                    <b>+ Pick-up inspection:</b> Before you drive off, do a
                    quick inspection to make sure the rental car is in tip-top
                    shape.{" "}
                  </li>
                  <li className="mb-3">
                    <b>+ Drop-off inspection:</b> Even if you are in a hurry,
                    make sure to double-check between the seats and inside
                    pockets for any belongings that might get left behind.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
