import React from "react";
import { Link } from "react-router-dom";
import SignModal from "../../common/header/SignModal";
import Axios from 'axios';
import { API_URL } from '../../core/constant';
import { formatDateTglBulanJam, getToken } from "../../core/functions";

export default class vmc_view extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleCancel = (e) => {
        e.preventDefault();
        Axios.get(
            `https://stage.abgapiservices.com/cars/reservation/v2?brand=Budget&confirmation_number=${this.state.bookingCode}&last_name=${this.state.last_name}`,
            {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('bebe'),
                    'client_id': '04e19f27'
                }
            },
        )
            .then(function (response) {
                // handle success
                // console.log(response.data);
                return response.data;
            })
            .then(function (data) {
                // handle success
                console.log("DATA RESPONSE: ", data);
                this.setState({ bookingData: data });
                this.setState({ isShowBooking: true });
            })
            .catch(function (error) {
                // handle error
                // alert("No cars available for your search criteria.");
                if (error.response.status == 401) {
                    getToken();
                    alert("Booking not found or Please try again");
                    window.location.reload();
                } else if (error.response.status == 400) {
                    alert("Please try again later");
                }
            })
            .finally(function () {
                // always executed

            });
    };

    showInclusions = (inclusions) => {
        const inclusionList = [];
        for (let index = 0; index < inclusions.length; index++) {
            inclusionList.push(
                <li key={index}><span style={{ color: 'rgb(247, 139, 0)' }}>&#10003;</span> {inclusions[index].name}</li>
            );
        }
        return inclusionList;
    }



    render() {
        let publicUrl = process.env.PUBLIC_URL + "/";
        const myStyle = {
            backgroundImage:
                "url(" + publicUrl + "assets/images/resources/reservation.avif)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        };
        const myLink = {
            color: "rgb(247, 139, 0)",
        };
        console.log("PROPS: ", this.props?.bookingData);
        return (
            <>
                {/* Start Slogan One */}
                <section style={myStyle}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5">
                                <h2 style={{ color: "#000" }}>View / Cancel</h2>
                            </div>
                        </div>
                        <div
                            className="row mt-5 mb-5"
                            style={{
                                backgroundColor: "#fff",
                                textAlign: "center",
                            }}
                        >
                            <div className="col-sm-12 p-4 text-avis">
                                <>
                                    <h3 style={myLink}>YOUR BOOKING</h3>

                                    <div style={{ backgroundColor: '#f0f1f2' }} className="mt-3">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h5 className="text-start p-3 text-dark fw-bold"><b>Your reference: {this.props?.bookingData?.transaction?.transaction_id}</b></h5>
                                            <div>
                                                <p style={{ fontSize: '14px', textAlign: 'left' }} className="text-start p-3 text-dark"><b>Booking Code: <b>{this.props?.bookingData?.reservation?.confirmation?.number}</b></b></p>z
                                                <p style={{ fontSize: '14px', textAlign: 'left' }} className="text-start p-3 text-dark"><b>Status Booking: <b>{this.props?.bookingDataApi?.status_book}</b></b></p>
                                            </div>
                                        </div>

                                        <div style={{ borderTop: '1px solid #dedfe0', borderBottom: '1px solid #dedfe0', display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                            <div style={{ color: '#000', flexGrow: '1.5', }} className="text-start p-3">
                                                <span style={myLink}>When & Where</span><br /><br />

                                                {formatDateTglBulanJam(this.props?.bookingData?.reservation?.confirmation?.pickup_date)} → {formatDateTglBulanJam(this.props?.bookingData?.reservation?.confirmation?.dropoff_date)}<br />
                                                {this.props?.bookingData?.reservation?.pickup_location?.location?.name}
                                            </div>
                                            <div style={{ color: "#000", flexGrow: '2' }} className="text-start p-3">
                                                <span style={myLink}>Vehicle</span><br /><br />

                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <p>{this.props?.bookingData?.reservation?.vehicle?.category?.model}</p>
                                                    {/* <p>$151</p> */}
                                                </div>
                                                <p style={{ fontSize: '13px' }}>{this.props?.bookingData?.reservation?.vehicle?.category?.name}</p>

                                                <img className="mt-3" src={this.props?.bookingData?.reservation?.vehicle?.category?.image_url ?? this.props?.bookingDataApi?.rent_detail?.car_img} alt="IMAGE CAR" width={200} />
                                            </div>
                                            <div style={{ color: "#000", flexGrow: '1.5' }} className="text-start p-3">
                                                <span style={myLink}>Extras</span><br /><br />

                                                <p>None Selected</p>
                                            </div>
                                        </div>

                                        <h5 className="text-end p-3 fw-bold text-dark">Total: {this.props?.bookingData?.reservation?.rate_totals?.rate?.currency} {this.props?.bookingData?.reservation?.rate_totals?.rate?.amount}</h5>
                                    </div>

                                    <div className="p-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', width: '100%' }}>
                                        <div className="me-3" style={{ flexGrow: '1' }}>
                                            <h5 className="text-start text-dark fw-bold"><b>When you collect your car</b></h5>

                                            <p style={{ color: 'black', textAlign: 'left', }}>
                                                One of your payment cards must be in the name of the main driver<br /> (unless booked on a centrally billed company credit card or account)<br /> and you'll need to present the following at the counter:
                                            </p>

                                            <ul className="mt-3 text-start">
                                                <li><span style={myLink}>&#10003;</span> A valid driving license</li>
                                                <li><span style={myLink}>&#10003;</span> 1 payment card</li>
                                            </ul>
                                        </div>
                                        <div className="flex-grow-1" style={{ flexGrow: '2' }}>
                                            <p className="text-start text-dark fw-bold">Your reservation includes:</p>

                                            <p style={{ color: 'black', textAlign: 'left', }}>
                                                (inclusions are subject to your agreed/contracted rates)
                                            </p>

                                            <ul className="mt-3 text-start">
                                                {this.showInclusions(this.props?.bookingData?.reservation?.insurance)}
                                            </ul>
                                        </div>
                                    </div>

                                    <div style={{ backgroundColor: '#f0f1f2' }} className="mt-3 p-3">
                                        <h5 className="text-start text-dark fw-bold"><b>Getting to your rental station</b></h5>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', width: '100%' }}>

                                            <div>
                                                <h6 className="text-start mt-3 fw-bold text-dark">Pick Up</h6>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', marginTop: '10px' }}>
                                                    <p style={{ color: 'black', textAlign: 'left', marginRight: '20px' }}>
                                                        {this.props?.bookingData?.reservation?.pickup_location?.location?.name}<br />
                                                        {this.props?.bookingData?.reservation?.pickup_location?.address?.address_line_1}<br />
                                                        {this.props?.bookingData?.reservation?.pickup_location?.address?.address_line_2}<br />
                                                        {this.props?.bookingData?.reservation?.pickup_location?.address?.country_code}
                                                    </p>
                                                    <p style={{ color: 'black', textAlign: 'left', }}>
                                                        Opening times on your pick up date:<br /> 05:00 - 23:59
                                                    </p>
                                                </div>

                                                <p className="text-start mt-3">Tel: {this.props?.bookingData?.reservation?.pickup_location?.location?.telephone}</p>
                                            </div>
                                            <div>
                                                <h6 className="text-start mt-3 fw-bold text-dark">Drop Off</h6>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', marginTop: '10px' }}>
                                                    <p style={{ color: 'black', textAlign: 'left', marginRight: '20px' }}>
                                                        {this.props?.bookingData?.reservation?.dropoff_location?.location?.name}<br />
                                                        {this.props?.bookingData?.reservation?.dropoff_location?.address?.address_line_1}<br />
                                                        {this.props?.bookingData?.reservation?.dropoff_location?.address?.address_line_2}<br />
                                                        {this.props?.bookingData?.reservation?.dropoff_location?.address?.country_code}
                                                    </p>
                                                    <p style={{ color: 'black', textAlign: 'left', }}>
                                                        Opening times on your pick up date:<br /> 05:00 - 23:59
                                                    </p>
                                                </div>

                                                <p className="text-start mt-3">Tel: {this.props?.bookingData?.reservation?.pickup_location?.location?.telephone}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                                        <button type="button" className="btn btn-lg" style={{ backgroundColor: '#f0f1f2', color: 'black', marginRight: '10px' }} onClick={() => this.props.navigate('/view-cancel')}>Back</button>
                                        {/* <button type="button" className="btn btn-lg" style={{ backgroundColor: 'rgb(247, 139, 0)', color: 'white' }}>Cancel</button> */}
                                    </div>
                                </>
                            </div>
                        </div>
                        <div className="row mt-5 pb-5">

                        </div>
                    </div>
                </section >
                {/* End Slogan One */}
            </>
        );
    }
}