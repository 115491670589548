import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from "wowjs";
import { FaAngleUp } from "react-icons/fa";

//Pages
import HomeDefault from "./pages/HomeDefault";

import Register from "./pages/Register";

import BusinessRentals from "./pages/BusinessRentals";

import CustomerCare from "./pages/CustomerCare";

import FAQ from "./pages/FAQ";

import MakeReservation from "./pages/MakeReservation";
import ViewModifyCancel from "./pages/ViewModifyCancel";
import GetReceipt from "./pages/GetReceipt";
import BudgetPreCheck from "./pages/BudgetPreCheck";

import BookingDetail from "./pages/BookingDetail";

import ChooseCars from "./pages/ChooseCars";
import Extra from "./pages/Extra";
import ReviewAndBook from "./pages/ReviewAndBook";

import Deals from "./pages/Deals";
import International from "./pages/International";
import PartnerOffers from "./pages/PartnerOffers";

import CarGuides from "./pages/CarGuides";
import PopularRental from "./pages/PopularRental";
import ProductService from "./pages/ProductService";
import Program from "./pages/Program";
import Protection from "./pages/Protection";
import Service from "./pages/Service";
import LongTermRental from "./pages/LongTermRental";
import MeetingGroup from "./pages/MeetingGroup";

import FastBreak from "./pages/FastBreak";

import FindLocation from "./pages/FindLocation";
import AirportLocation from "./pages/AirportLocation";
import USLocation from "./pages/USLocation";
import GlobalLocation from "./pages/GlobalLocation";
import TripIdeas from "./pages/TripIdeas";

import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import ListingsOne from "./pages/ListingsOne";
import ListingsTwo from "./pages/ListingsTwo";
import ListingsThree from "./pages/ListingsThree";
import ListingsDetails from "./pages/ListingsDetails";
import Categories from "./pages/Categories";
import Visited from "./pages/Visited";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import ScrollToTopRoute from "./components/scroll-to-top-route/ScrollToTopRoute";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import LuxuryCar from "./pages/LuxuryCar";
import RentalCarSizes from "./pages/RentalCarSizes";
import PickupTruck from "./pages/PickupTruck";
import VanCar from "./pages/VanCar";
import SuvCar from "./pages/SuvCar";
import CarExploreDetails from "./pages/CarExploreDetails";
import ContactUs from "./pages/ContactUs";
import BestRateGuarantee from "./pages/BestRateGuarantee";
import RoadsideAssitance from "./pages/RoadsideAssitance";
import RentalCarFuelPlans from "./pages/RentalCarFuelPlans";
import SiteMap from "./pages/SiteMap";
import FraudScams from "./pages/FraudScams";

import OneWayCarRentalN from "./pages/OneWayCarRentalN";
import AdditionalDriver from "./pages/AdditionalDriver";
import FreeUpgrade from "./pages/FreeUpgrade";
import Affiliates from "./pages/Affiliates";
import TravelAgent from "./pages/TravelAgent";
import MilesPointsPartners from "./pages/MilesPointsPartners";
import Rewards from "./components/miles-points/Rewards";
import AARPMembers from "./pages/AARPMembers";
import MilitaryVeterans from "./pages/MilitaryVeterans";
import CopyrightNotices from "./pages/CopyrightNotices";
import TermsofUse from "./pages/TermsofUse";
import PrivacyNotice from "./pages/PrivacyNotice";
import IATACarRental from "./pages/IATACarRental";
import ReservationTools from "./pages/ReservationTools";
import TravelAgentContact from "./pages/TravelAgentContact";
import TravelAgentDiscount from "./pages/TravelAgentDiscount";
import CommissionPolicy from "./pages/CommissionPolicy";
import Hotel from "./components/miles-points/Hotel";
import Airlines from "./pages/Airlines";
import OtherPartners from "./pages/OtherPartners";
import CompanyInfo from "./pages/CompanyInfo";
import RentalExecutiveTeam from "./pages/RentalExecutiveTeam";
import OurMission from "./pages/OurMission";
import CorporateResponsibility from "./pages/CorporateResponsibility";
import AboutUs from "./pages/AboutUs";
import MilestoneEvents from "./pages/MilestoneEvents";
import RentalInternationalWebsites from "./pages/RentalInternationalWebsites";
import AirlinesDetails from "./pages/AirlinesDetails";
import CarRentalPopularDestination from "./pages/CarRentalPopularDestination";
import Signup from "./pages/Signup";
import BusinessRental from "./pages/BusinessRental";
import DetailProtection from "./pages/DetailProtection";
import BudgetWorryPromise from "./pages/BudgetWorryPromise";

import BookingList from "./pages/BookingList";
import UserHome from "./pages/UserHome";
import ConfirmBooking from "./pages/ConfirmBooking";
import SweeterRides from "./pages/SweeterRides";
import ChildSafetySeats from "./pages/ChildSafetySeats";
import GpsNavigation from "./pages/GpsNavigation";
import "react-datepicker/dist/react-datepicker.css";
import ViewBooking from "./pages/ViewBooking";

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <Router>
      <ScrollToTopRoute />
      <Routes>
        <Route
          path={`${process.env.PUBLIC_URL}/`}
          exact
          element={<HomeDefault />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/gps-navigation`}
          exact
          element={<GpsNavigation />}
        />
        {/* Register */}
        <Route
          path={`${process.env.PUBLIC_URL}/register`}
          exact
          element={<Signup />}
        />
        {/* End Register */}
        {/* Businees Rentals */}
        <Route
          path={`${process.env.PUBLIC_URL}/businessrentals`}
          exact
          element={<BusinessRentals />}
        />
        {/* End Businees Rentals */}
        {/* Customer Care */}
        <Route
          path={`${process.env.PUBLIC_URL}/customercare`}
          exact
          element={<CustomerCare />}
        />
        {/* End Customer Care */}
        {/* FAQ */}
        <Route path={`${process.env.PUBLIC_URL}/faq`} exact element={<FAQ />} />
        {/* FAQ */}
        {/* Reservation */}
        <Route
          path={`${process.env.PUBLIC_URL}/makereservation`}
          exact
          element={<MakeReservation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/view-cancel`}
          exact
          element={<ViewModifyCancel />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/getreceipt`}
          exact
          element={<GetReceipt />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/budgetprecheck`}
          exact
          element={<BudgetPreCheck />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/choosecars`}
          exact
          element={<ChooseCars />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/extra`}
          exact
          element={<Extra />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/reviewandbook`}
          exact
          element={<ReviewAndBook />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/bookingdetail`}
          exact
          element={<BookingDetail />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/booking/confirm`}
          exact
          element={<ConfirmBooking />}
        />
        {/* End Reservation */}
        {/* Auth User */}
        <Route
          path={`${process.env.PUBLIC_URL}/userhome`}
          exact
          element={<UserHome />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/view_booking`}
          exact
          element={<ViewBooking />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/bookinglist`}
          exact
          element={<BookingList />}
        />
        {/* End Auth User */}
        {/* Deals */}
        <Route
          path={`${process.env.PUBLIC_URL}/deals`}
          exact
          element={<Deals />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/international`}
          exact
          element={<International />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/partner-offers`}
          exact
          element={<PartnerOffers />}
        />
        {/* End Deals */}
        {/* Cars & Services */}
        <Route
          path={`${process.env.PUBLIC_URL}/carguides`}
          exact
          element={<CarGuides />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/popularrental`}
          exact
          element={<PopularRental />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/products`}
          exact
          element={<ProductService />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/products/child-safety-seats`}
          exact
          element={<ChildSafetySeats />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/program`}
          exact
          element={<Program />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/protection`}
          exact
          element={<Protection />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/detail/protection/:slug`}
          exact
          element={<DetailProtection />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/services`}
          exact
          element={<Service />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/longtermrental`}
          exact
          element={<LongTermRental />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/meetinggroup`}
          exact
          element={<MeetingGroup />}
        />

        {/* End Cars & Services */}
        {/* Fastbreak */}
        <Route
          path={`${process.env.PUBLIC_URL}/fastbreak`}
          exact
          element={<FastBreak />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/fastbreak/enrollment`}
          exact
          element={<Register />}
        />
        {/* End Fastbreak */}
        {/* Business Rental */}
        <Route
          path={`${process.env.PUBLIC_URL}/business-car-rental`}
          exact
          element={<BusinessRental />}
        />
        {/* End Business Rental */}
        {/* Locations */}
        <Route
          path={`${process.env.PUBLIC_URL}/findlocation`}
          exact
          element={<FindLocation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/airportlocation`}
          exact
          element={<AirportLocation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/uslocation`}
          exact
          element={<USLocation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/globallocation`}
          exact
          element={<GlobalLocation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/tripideas`}
          exact
          element={<TripIdeas />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/locations/*`}
          element={<CarRentalPopularDestination />}
        />
        {/* End Locations */}
        {/* Popular Searches */}
        <Route
          path={`${process.env.PUBLIC_URL}/onewaycarrental`}
          exact
          element={<OneWayCarRentalN />}
        />
        {/* <Route
          path={`${process.env.PUBLIC_URL}/freeupgrade`}
          exact
          element={<FreeUpgrade />}
        /> */}
        {/* End Popular Searches */}
        <Route
          path={`${process.env.PUBLIC_URL}/home-two`}
          exact
          element={<HomeTwo />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-three`}
          exact
          element={<HomeThree />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about`}
          exact
          element={<About />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/listings1`}
          exact
          element={<ListingsOne />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/listings2`}
          exact
          element={<ListingsTwo />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/listings3`}
          exact
          element={<ListingsThree />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/listings-details`}
          exact
          element={<ListingsDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/categories`}
          exact
          element={<Categories />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/visited`}
          exact
          element={<Visited />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/team`}
          exact
          element={<Team />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/team-details`}
          exact
          element={<TeamDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog`}
          exact
          element={<Blog />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-details`}
          exact
          element={<BlogDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/contact`}
          exact
          element={<Contact />}
        />
        <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<Error />} />
        {/* luxury Car Rental */}
        <Route
          path={`${process.env.PUBLIC_URL}/luxury-car-rental`}
          exact
          element={<LuxuryCar />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/car-explore`}
          exact
          element={<CarExploreDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/rent-car-sizes`}
          exact
          element={<RentalCarSizes />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/pickup-truck-rent`}
          exact
          element={<PickupTruck />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/suv-rental`}
          exact
          element={<SuvCar />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/van-rental`}
          exact
          element={<VanCar />}
        />
        {/* Budget Support */}
        <Route
          path={`${process.env.PUBLIC_URL}/contact-us`}
          exact
          element={<ContactUs />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/best-rate-guarantee`}
          exact
          element={<BestRateGuarantee />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/roadside-assistance`}
          exact
          element={<RoadsideAssitance />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/fuel-plans`}
          exact
          element={<RentalCarFuelPlans />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/additional-driver`}
          exact
          element={<AdditionalDriver />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/budget-worry-free-promise`}
          exact
          element={<BudgetWorryPromise />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/sitemap`}
          exact
          element={<SiteMap />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/customer-care`}
          exact
          element={<FraudScams />}
        />
        {/* Budget Partners */}
        <Route
          path={`${process.env.PUBLIC_URL}/affiliates`}
          exact
          element={<Affiliates />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/travel-agent`}
          exact
          element={<TravelAgent />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/travel-agent/iata-car-rental`}
          exact
          element={<IATACarRental />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/travel-agent/gds-reservation-tools`}
          exact
          element={<ReservationTools />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/travel-agent/contact-us`}
          exact
          element={<TravelAgentContact />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/travel-agent/travel-agent-deals`}
          exact
          element={<TravelAgentDiscount />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/travel-agent/commissions-policy`}
          exact
          element={<CommissionPolicy />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/miles-points-partners`}
          exact
          element={<MilesPointsPartners />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/miles-points-partners/airlines`}
          exact
          element={<Airlines />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/miles-points-partners/airlines/:details`}
          exact
          element={<AirlinesDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/miles-points-partners/hotel`}
          exact
          element={<Hotel />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/miles-points-partners/other-partners`}
          exact
          element={<OtherPartners />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/miles-points-partners/hotel/:rewards`}
          exact
          element={<Rewards />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/aarp-members`}
          exact
          element={<AARPMembers />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/25-off-car-rentals-for-military-veterans`}
          exact
          element={<MilitaryVeterans />}
        />
        {/* Company Info */}
        <Route
          path={`${process.env.PUBLIC_URL}/about-budget`}
          exact
          element={<About />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-budget/company-information`}
          exact
          element={<CompanyInfo />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-budget/company-information/leader-profiles`}
          exact
          element={<RentalExecutiveTeam />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-budget/company-information/our-mission`}
          exact
          element={<OurMission />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-budget/company-information/corporate-responsibility`}
          exact
          element={<CorporateResponsibility />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-budget/company-information/about-us`}
          exact
          element={<AboutUs />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-budget/company-information/milestone-events`}
          exact
          element={<MilestoneEvents />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/sweeter-rides`}
          exact
          element={<SweeterRides />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-budget/company-information/budget-web-sites-worldwide`}
          exact
          element={<RentalInternationalWebsites />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/copyright-notices`}
          exact
          element={<CopyrightNotices />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/terms-of-use`}
          exact
          element={<TermsofUse />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/privacy-notice`}
          exact
          element={<PrivacyNotice />}
        />
      </Routes>
      <ScrollToTop
        className="scrollUp"
        smooth
        top="1500"
        component={<FaAngleUp />}
      />
    </Router>
  );
}

export default App;
