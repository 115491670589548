import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import LandingSelectCar from "../components/road/LandingSelectCar";
import FooterOne from "../common/footer/FooterOne";
import Title from "../Title";

export default function GpsNavigation() {
  Title("GPS Navigation | Budget Car Rental");
  return (
    <>
      <HeaderOne />
      <LandingSelectCar
        image="cq5dam.web.1536.1008.avif"
        title="Navigate Your Trip with GPS"
        sub=""
        page=""
      />
      <section>
        <div className="container pt-3">
          <div className="row">
            <div className="col-sm-12">
              <h6>Add GPS navigation to your rental.</h6>
              <p>
                When travel takes you someplace new, there is no time to play
                hunt-and-seek. With GPS you can look up addresses, hotels, and
                attractions.
              </p>
              <hr />
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </>
  );
}
