import React, { useEffect, useState, Component } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { Link } from "react-router-dom";
import SignModal from "./SignModal";
import { API_URL } from "../../core/constant";

class HeaderAlt extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <header class="main-header main-header-one clearfix">
          <div class="main-header-one__wrapper">
            <div class="main-header-one__top">
              <div class="container">
                <div class="main-header-one__top-inner">
                  <div class="main-header-one__top-address">
                    <ul>
                      <li>
                        {/* <div class="icon"><span class="icon-pin"></span></div>
                                                <div class="text"><p>27/52 Avenue, NY USA 685.</p></div> */}
                      </li>

                      <li>
                        {/* <div class="icon"><span class="icon-clock"></span></div>
                                                <div class="text"><p>Mon - Sat 8.00 - 18.00. Sun Closed</p></div> */}
                      </li>
                    </ul>
                  </div>

                  <div class="main-header-one__top-address">
                    <ul>
                      <li>
                        {this.props.login || localStorage.getItem('token') ? (
                          <a href={publicUrl + "userhome"}>
                            <span className="fa fa-user"></span>{" "}
                            {this.props.name}
                          </a>
                        ) : (
                          <button>
                            <SignModal />
                          </button>
                        )}
                      </li>
                      {/* <li><a href='#'>Chat Now <span className="fa fa-comment"></span></a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="main-header-one__bottom">
              <nav class="main-menu main-menu--1">
                <div class="main-menu__wrapper">
                  <div class="container">
                    <div class="main-menu__wrapper-inner">
                      <div class="main-header-one__bottom-left">
                        <div class="logo">
                          <Link to={process.env.PUBLIC_URL + `/`}>
                            <img
                              src={publicUrl + "assets/images/icon/budget.png"}
                              alt=""
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </>
    );
  }
}

function HT(props) {
  const [login, setLogin] = useState(false);
  const [name, setName] = useState("");

  // Run Function when pages loaded
  useEffect(() => {
    Axios.post(
      API_URL + "/api/auth/profile",
      // 'https://backend.avis-id.com/public/api/auth/user',
      {

      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then(function (response) {
        // handle success
        // console.log(response.data);
        return response.data;
      })
      .then(function (data) {
        // handle success
        setLogin(true);
        setName(data?.name);
        console.log(data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);

  return <HeaderAlt {...props} login={login} name={name} />;
}

export default HT;
