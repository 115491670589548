import React from "react";
import BreadcrumbCar from "../luxury/BreadcrumbCar";
import CardPopular from "./CardPopular";

const ListPopular = () => {
  const breadcrumb = [
    "Locations",
    "Canada & USA",
    "United States",
    "Nevada",
    "Las Vegas",
  ];

  return (
    <div className="listPopularContainer">
      <BreadcrumbCar breadcrumb={breadcrumb} />
      {/* <hr /> */}
      <div className="py-4 container">
        <h3 className="similar">Las Vegas Car Rental & Nearby Locations</h3>
        <div className="destinations">
          <CardPopular />
          <hr />
          <CardPopular />
          <hr />
          <CardPopular />
        </div>
      </div>
    </div>
  );
};

export default ListPopular;
