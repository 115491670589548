import React from "react";
import Card from "../../common/card/Card";

const ContentFraudScam = () => {
  return (
    <div className="carsContainer">
      <div className="container py-5 px-4">
        <div className="row">
          <Card
            title="Contact Us"
            description="Find answers to all your Budget rental car questions in our FAQ. From age restrictions to credit and insurance questions you'll get the answers here."
            link="/contact-us"
          />
          <Card
            title="Reservation / Cancelation"
            description="Find answers to all your Budget rental car questions in our FAQ. From age restrictions to credit and insurance questions you'll get the answers here."
            link="/view-cancel"
          />
          <Card
            title="Site Map"
            description="Find answers to all your Budget rental car questions in our FAQ. From age restrictions to credit and insurance questions you'll get the answers here."
            link="/sitemap"
          />
          <Card
            title="Rental Location Terms and Conditions"
            description="Find answers to all your Budget rental car questions in our FAQ. From age restrictions to credit and insurance questions you'll get the answers here."
            link="https://www.budget.co.uk/TermsAndCondition"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentFraudScam;
