import React from "react";
import { Link } from "react-router-dom";

const History = () => {
  return (
    <div className="container py-4">
      <p className="mb-2">
        <b>1958</b>
      </p>
      <p className="mb-2">
        Budget Rent A Car is founded in Los Angeles by Morris Mirkin with a
        $10,000 investment. The company name is chosen to appeal to the
        "budget-minded" or “value-conscious” renter.{" "}
      </p>
      <p className="mb-2">
        <b>1959</b>
      </p>
      <p className="mb-2">
        Mirkin's distant relative, Jules Lederer becomes enamored with the
        Budget concept and creates a leasing company to help Mirkin increase his
        fleet and grow the business.
      </p>
      <p className="mb-2">
        <b>1960</b>
      </p>
      <p className="mb-2">
        Lederer and Mirkin form Budget Rent A Car Corporation in Chicago, and
        the company grows rapidly, both nationally and internationally.{" "}
      </p>
      <p className="mb-2">
        <b>1986</b>
      </p>
      <p className="mb-2">
        Gibbons, Green and van Amerongen Ltd., along with management and
        selected investors, buy Budget from Transamerica Corp. in a leveraged
        buyout transaction.{" "}
      </p>
      <p className="mb-2">
        <b>1987</b>
      </p>
      <p className="mb-2">
        Budget announces its initial public offering of 3.2 million shares.
      </p>
      <p className="mb-2">
        <b>1992</b>
      </p>
      <p className="mb-2">
        Budget moves its long-time downtown Chicago headquarters to Lisle, IL.
      </p>
      <p className="mb-2">
        <b>1997</b>
      </p>
      <p className="mb-2">
        Budget is bought by Team Rental Group, Budget’s largest U.S. franchisee,
        and changes its name to Budget Group, Inc. Budget Group begins trading
        on the NYSE. Later that year, Budget unveils “Unlimited Budget,” a
        travel agent loyalty program through which members can receive a free
        Unlimited Budget debit MasterCard and earn valuable points for completed
        bookings.
      </p>
      <p className="mb-2">
        <b>1998</b>
      </p>
      <p className="mb-2">
        Budget introduces “Fastbreak,” its paperless-transaction express
        service.
      </p>
      <p className="mb-2">
        <b>2002</b>
      </p>
      <p className="mb-2">
        Budget Group is de-listed from the NYSE and sells its Budget Rent A Car
        assets in the U.S., Canada, Latin America, the Caribbean, Australia and
        New Zealand to Cendant Corporation.
      </p>
      <p className="mb-2">
        Other worldwide assets are sold to Avis Europe plc.{" "}
      </p>
      <p className="mb-2">
        Cendant moves Budget headquarters from Lisle, IL to Parsippany, NJ.
      </p>
      <p className="mb-2">
        <b>2003</b>
      </p>
      <p className="mb-2">
        Budget rolls out the “Budget Business Program,” a corporate discount
        program that offers small and mid-sized businesses low corporate rates,
        complimentary Fastbreak service and no additional driver fees.
      </p>
      <p className="mb-2">
        Budget launches “Budget Pride,” a comprehensive employee motivation
        program.
      </p>
      <p className="mb-2">
        <b>2006</b>
      </p>
      <p className="mb-2">
        Cendant Corporation separates into four independent companies: Realogy,
        Wyndham Worldwide, Travelport, and Avis Budget Group, Inc. Through this
        separation, Budget Rent A Car System, Inc. is now a subsidiary of Avis
        Budget Group.
      </p>
      <p className="mb-2">
        <b>2009</b>
      </p>
      <p className="mb-2">
        Avis Budget Group responds to customer feedback and institutes a
        smoke-free policy in its vehicles. The Company also establishes a
        smoke-free policy for all employees and contractors who drive its
        vehicles.
      </p>
      <p className="mb-2">
        <b>2011</b>
      </p>
      <p className="mb-2">
        Avis Budget Group acquires Avis Europe plc. The transaction re-unites
        Avis Budget Group with its licensee for Europe, the Middle East, Africa
        and Asia.
      </p>
      <p className="mb-2">
        Avis Budget Group creates three operating regions. These include: North
        America, Europe, Middle East and Africa (EMEA), and Latin America/Asia.
      </p>
      <Link
        to={`${process.env.PUBLIC_URL}/makereservation`}
        className="btnCar d-inline-block mt-3 ms-3"
      >
        Make a Reservation
      </Link>
    </div>
  );
};

export default History;
