import React from "react";
import { Link } from "react-router-dom";

const Other = () => {
  return (
    <div className="container py-4">
      <h1 className="mb-3">Other Partners</h1>
      <p className="mb-3">
        Of course you choose Budget for our reliable rental cars and great
        prices. But Budget also offers car rental partner deals to help you save
        even more money—not just on rental cars, but on other aspects of your
        travels. Discover additional discounts from Budget car rental partners
        below!{" "}
      </p>
      <p className="mb-5">
        Take advantage of these discounts and savings from Budget rental car
        partners when you book a vehicle today!
      </p>
      <div className="row mb-3">
        <div className="col-md-4">
          <div className="d-flex flex-column">
            <p className="mb-2">
              <Link className="linked">AIR MILES Reward Miles</Link>
            </p>
            <p className="mb-3">
              In Canada and the U.S., accumulate air miles based on your car
              rental payments and get discounted rental cars too!
            </p>
            <p className="mb-2">
              <Link className="linked">American Airlines Business ExtrAA</Link>
            </p>
            <p className="mb-3">
              When you rent a Budget car for three or more days, you can earn
              500 miles and receive a special discount.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-column">
            <p className="mb-2">
              <Link className="linked">Marriott Vacation Club</Link>
            </p>
            <p className="mb-3">
              Make the most of your time off with the perks offered by Marriott
              Vacation Club.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-column">
            <p className="mb-2">
              <Link className="linked">Upromise</Link>
            </p>
            <p className="mb-3">
              Begin saving for your children’s college tuition while spending on
              Budget car rentals. Free to join!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Other;
