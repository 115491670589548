import React from "react";
import Card from "../../common/card/Card";

const Info = () => {
  return (
    <div className="carsContainer">
      <div className="container py-5 px-4">
        <div className="row py-4">
          <Card
            title="About Us"
            description="Budget combines the strength of one of the world's largest car rental brands together with detailed local knowledge, flexibility and great value for money."
            link="/about-budget/company-information/about-us"
          />
          <Card
            title="A Brief History of Budget "
            description="Historical Milestones"
            description2="A timeline look at the Budget Car Rental corporate history. See how Budget has grown through the years into a globally recognized and respected rental car company."
            link="/about-budget/company-information/milestone-events"
          />
          <Card
            title="Copyright Notice "
            description="Automotive Images, Copyright [2000 – 2019] izmo, Inc. All Rights Reserved."
            link="/copyright-notices"
          />
        </div>
      </div>
    </div>
  );
};

export default Info;
