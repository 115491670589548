import React from "react";
import FooterOne from '../common/footer/FooterOne';
import HeaderAlt from "../common/header/HeaderAlt";
import Reg from "../components/register/Reg";

const Register = () => {
    return (
        <>
            <HeaderAlt />
            <Reg />
            <FooterOne />
        </>
    )
}

export default Register;