import React from "react";
import { Link } from "react-router-dom";
import List from "./List";

const ContentSiteMap = () => {
  return (
    <>
      <hr />
      <h3>Reservation</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/makereservation"}>Make a Reservation</Link>
          </p>
          <p>
            <Link to={"/view-cancel"}>
              Reservation Confirmation/Cancellation
            </Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Event and Specials</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/international"}>Promotion</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Carguides</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/van-rental"}>Vans with seating for 7-15</Link>
          </p>
          <p>
            <Link to={"/suv-rental"}>Spacious SUVs of all sizes</Link>
          </p>
          <p>
            <Link to={"/pickup-truck-rent"}>
              Pickup trucks for bringing gear along
            </Link>
          </p>
          <p>
            <Link to={"/rent-car-sizes"}>Sedans from economy to deluxe</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Product and Services</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/productservice/products"}>Products</Link>
          </p>
          <p>
            <Link to={"/productservice/services"}>Services</Link>
          </p>
          <p>
            <Link to={"/productservice/protection"}>
              Protection and Coverages
            </Link>
          </p>
          <p>
            <Link to={"/productservice/protection"}>Programs</Link>
          </p>
        </div>
        <div className="col-md-4">
          <p>
            <Link>Military Veterans Car Rental Discount</Link>
          </p>
          <p>
            <Link>AARP Members Car Rental Discount</Link>
          </p>
          <p>
            <Link>Airline Partner Rental Car Discounts</Link>
          </p>
          <p>
            <Link>Hotel Partner Rental Car Discounts</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Locations</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/findlocation"}>Find a Location</Link>
          </p>
          <p>
            <Link to={"/airportlocation"}>Top Airport Location</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Popular Searches Terms</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <a
              href="https://faq.budget.co.uk/?Brand=Budget"
              target="_blank"
              rel="noreferrer"
            >
              Frequently Asked Questions
            </a>
          </p>
          <p>
            <Link to={"/onewaycarrental"}>One-Way Car Rental</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Services and Programs</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/productservice/protection"}>
              Protections and Coverages
            </Link>
          </p>
          <p>
            <Link to={"/roadside-assistance"}>Roadside Assistance Plus</Link>
          </p>
          <p>
            <Link to={"/roadside-assistance"}>Fuel Plan</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Customer Support</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/contact-us"}>Contact Us</Link>
          </p>
          <p>
            <Link to={"/sitemap"}>Site Map</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Company Information</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/about-budget"}>About Budget</Link>
          </p>
          <p>
            <Link to={"/about-budget/company-information/milestone-events"}>
              A Brief History
            </Link>
          </p>
          <p>
            <Link to={"/copyright-notices"}>Copyright Notice</Link>
          </p>
          <p>
            <Link to={"/terms-of-use"}>Term of Use</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ContentSiteMap;
