import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import PS from "../components/productservice/PS";
import Title from "../Title";
import { Link } from "react-router-dom";

export default function ChildSafetySeats() {
  Title("Child Safety Seats with your Car Rental | Budget Car Rental");
  return (
    <>
      <HeaderOne />
      <section className="border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 pt-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link>Products and Services</Link>
                  </li>
                  <li className="breadcrumb-item">Products</li>
                  <li className="breadcrumb-item linked">
                    <Link to={"/productservice/products/child-safety-seats"}>
                      Child Safety Seats
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5">
          <div className="row">
            <div className="col-sm-12">
              <h1>Child Safety Seats with your Car Rental</h1>
              <p className="mt-3">
                Safety is our number-one priority at Budget. We want the right
                restraint and child seat for your child. We recommend the
                following guidelines when selecting infant and child seats:
              </p>
              <ol className="mt-2">
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>Up to 1 year old (and up to 20-22 pounds)</p>
                    <p>
                      Rear-facing Infant-only car seat, Convertible or 3-in-1
                      seat
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>Ages 1-3 (and 20 to 40 pounds) </p>
                    <p>
                      Rear-facing car seat until the top height and weight limit
                      allowed by the manufacturer is exceeded.
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>Ages 4-7 (or until 4'9" tall)</p>
                    <p>
                      Forward-facing car seat until the top height and weight
                      limit allowed by the manufacturer is exceeded.
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>Age 8-12 (or at least over 4'9")</p>
                    <p>
                      Booster Seat with lap and shoulder belts until the top
                      height and weight limit allowed by the manufacturer is
                      exceeded
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>Big Kids Need Boosters</p>
                    <p>
                      The U.S. Government recommends that from 40 to about 80
                      pounds and about 4'9" tall, children should always be
                      seated in a belt-positioning booster. A booster seat lifts
                      children so adult lap/shoulder seat belts are "positioned"
                      correctly and safely. Plus, booster seats offer children
                      better visibility and comfort.
                    </p>
                  </div>
                </li>
              </ol>
              <p className="mt-3 mb-5">
                At{" "}
                <Link to={"/findlocation"} className="linked">
                  {" "}
                  corporate-owned locations
                </Link>
                , if your reservation is made 48 hours prior to pick up, we
                guarantee availability of a child safety seat. Within 48 hours
                of pick up and at locations outside the U.S. and{" "}
                <Link to={"/findlocation"} className="linked">
                  licensee-owned locations
                </Link>{" "}
                , your request will be held with your{" "}
                <Link to={"/makereservation"} className="linked">
                  {" "}
                  reservation
                </Link>
                , but the rental is made on a first-come, first-served basis, so
                availability is not guaranteed.
              </p>
              <div className="mb-5">
                <Link to={"/makereservation"}>
                  <button
                    className="btn align-self-center"
                    style={{
                      backgroundColor: "rgb(0, 40, 95)",
                      color: "#fff",
                      padding: "0px 50px",
                      height: "50px",
                    }}
                  >
                    Make a Reservation
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </>
  );
}
