import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Header from "../common/header/Header";
import Corporate from "../components/about/Corporate";

const CorporateResponsibility = () => {
  const breadcrumb = [
    "About Budget Rent A Car",
    "Company Information",
    "Corporate Responsibility",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Header
        title="Budget USA: Corporate Responsibility"
        subtitle="Building strong community relationships"
      />
      <Corporate />
      <FooterOne />
    </>
  );
};

export default CorporateResponsibility;
