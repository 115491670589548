import React from "react";
import { Link } from "react-router-dom";

export default class Ser extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/productservice.avif)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // height: "200px"
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
      minHeight: "10px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link>Products and Services</Link>
                    </li>
                    <li className="breadcrumb-item myLink" aria-current="page">
                      Services
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Budget Rental Car Services</h1>
                {/* <label className='my-3'><b>Peace of Mind for Your Next Car Rental</b></label>
                                <p className='mb-3'>Looking for rental car insurance coverage? If so, you’re making a smart move that will protect you in unfortunate circumstances that may arise while driving your vehicle. Budget has a diverse selection of rental car protection choices. Whether you’re traveling for business or pleasure, with family or alone, you’re sure to find the right insurance plan for you. The right rental car insurance will give you peace of mind, no matter what situations come up on the road. Find plans to safeguard you in case of accident, injury, damage, or theft. Check out our rental car insurance options above.</p> */}
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-4">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={process.env.PUBLIC_URL + `/fuel-plans`}>
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        Fuel Plan
                      </label>
                      <label style={myParas}>
                        Road-trip adventures are sometimes spontaneous, and you
                        don’t know where the drive can lead you. That’s why we
                        have 3 fuelling options for you, suitable for any drive.
                        Learn more!
                      </label>
                      <label style={myLink}>Learn More </label>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={process.env.PUBLIC_URL + `/roadside-assistance`}>
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        Roadside Assistance Plus
                      </label>
                      <label style={myParas}>
                        Get the optional Roadside Assistance Plus from Budget to
                        have Key replacement, flat tire replacement, jump starts
                        and more at your fingertips.
                      </label>
                      <label style={myLink}>Learn More </label>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={process.env.PUBLIC_URL + `/onewaycarrental`}>
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        One Way Car Rental
                      </label>
                      <label style={myParas}>
                        Plan your one way trip to pick up your rental car at one
                        location and return to another. Visit Budget car rental
                        for competitive rates.
                      </label>
                      <label style={myLink}>Learn More </label>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={process.env.PUBLIC_URL + `/additional-driver`}>
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        Additional Drivers
                      </label>
                      <label style={myParas}>
                        Share the drive and add one or more additional drivers
                        to your rental with Budget.
                      </label>
                      <label style={myLink}>Learn More </label>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={process.env.PUBLIC_URL + `/budget-worry-free-promise`}
                >
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        The Budget Worry Free Promise
                      </label>
                      <label style={myParas}>
                        The assurance that we’ve taken countless safety measures
                        to make the experience safe for everyone.
                      </label>
                      <label style={myLink}>Learn More </label>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
