import React from "react";
import { Link } from "react-router-dom";

export default class FooterOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        {/* Start Footer One */}
        <footer className="footer-one">
          <div className="footer-one__top">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="footer-one__top-inner">
                    <div className="row">
                      <div
                        className="col-xl-3 col-lg-6 col-md-6 wow  fadeInUp animated"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                        }}
                      >
                        <div className="footer-widget__column footer-widget__services">
                          <h2 className="footer-widget__title">
                            Popular Searches Terms
                          </h2>
                          <ul className="footer-widget__services-list">
                            <li className="footer-widget__services-list-item">
                              <Link to={process.env.PUBLIC_URL + `/`}>
                                Frequently Asked Questions
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={process.env.PUBLIC_URL + `/onewaycarrental`}
                              >
                                One-Way Car Rental
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 col-lg-6 col-md-6 wow  fadeInUp animated"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                        }}
                      >
                        <div className="footer-widget__column footer-widget__services">
                          <h2 className="footer-widget__title">
                            Services and Programs
                          </h2>
                          <ul className="footer-widget__services-list">
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  `/productservice/protection`
                                }
                              >
                                Protections and Coverages
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  `/roadside-assistance`
                                }
                              >
                                Roadside Assistance Plus
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link to={process.env.PUBLIC_URL + `/fuel-plans`}>
                                Fuel Plan
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 col-lg-6 col-md-6 wow  fadeInUp animated"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                        }}
                      >
                        <div className="footer-widget__column footer-widget__services">
                          <h2 className="footer-widget__title">
                            Customer Support
                          </h2>
                          <ul className="footer-widget__services-list">
                            <li className="footer-widget__services-list-item">
                              <Link to={process.env.PUBLIC_URL + `/contact-us`}>
                                Contact Us
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link to={process.env.PUBLIC_URL + `/sitemap`}>
                                Site Map
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 col-lg-6 col-md-6 wow  fadeInUp animated"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                        }}
                      >
                        <div className="footer-widget__column footer-widget__services">
                          <h2 className="footer-widget__title">
                            Company Information
                          </h2>
                          <ul className="footer-widget__services-list">
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={process.env.PUBLIC_URL + `/about-budget`}
                              >
                                About Budget
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={`${process.env.PUBLIC_URL}/about-budget/company-information/milestone-events`}
                              >
                                A Brief History
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={`${process.env.PUBLIC_URL}/copyright-notices`}
                              >
                                Copyright Notice
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={`${process.env.PUBLIC_URL}/terms-of-use`}
                              >
                                Terms of Use
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={`${process.env.PUBLIC_URL}/privacy-notice`}
                              >
                                Privacy Policy
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Start Footer One Bottom */}
          <div
            className="footer-one__bottom clearfix"
            style={{ backgroundColor: "#140a55" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="footer-one__bottom-inner text-center">
                    <div className="footer-one__bottom-text">
                      <p>
                        Copyright © 2022{" "}
                        <Link to={process.env.PUBLIC_URL + `/`}>Budget.</Link>{" "}
                        All Rights Reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Footer One Bottom */}
        </footer>
        {/* End Footer One */}
      </>
    );
  }
}
