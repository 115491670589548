import React from 'react';
import {Link} from 'react-router-dom';

export default class CC extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/';
        const myBg = {
            backgroundColor: "#eaeaea" 
        }
        const myLink = {
            color: "rgb(237, 85, 5)",
        }
        const myHead = {
            fontSize: "18px"
        }
        const myPara = {
            lineHeight: "1.5",
            fontSize: "15px",
            color: "#000",
            height: "175px"
        }
        const myCard = {
            backgroundColor: "#fff"
        }
        return (
            <>
                {/* Start Slogan One */}
                <section >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-12 py-5'>
                                <h2 className=''>Customer Care</h2>
                                <p className='mt-2' style={myLink}> Question? You've come to the right place!</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={myBg} className="py-5">
                    <div className='container'>
                        <div className='row'>
                            <a href='#' className='col-lg-4 col-sm-6 pe-5 pb-5'>
                                <div className='row'>
                                    <div className='col-12 p-3' style={myCard}>
                                        <label className='mb-2' style={myHead}>Fraud & Scams</label>
                                        <label style={myPara}>Protect yourself from becoming a victim of car rental scams and fraud. Click the link below for common signs of people who may try to scam both you and Budget.</label>
                                        <label style={myLink}>Learn More </label>
                                    </div>
                                </div>
                            </a>
                            <a href='#' className='col-lg-4 col-sm-6 pe-5 pb-5'>
                                <div className='row'>
                                    <div className='col-12 p-3' style={myCard}>
                                        <label className='mb-2' style={myHead}>Fraud & Scams</label>
                                        <label style={myPara}>Protect yourself from becoming a victim of car rental scams and fraud. Click the link below for common signs of people who may try to scam both you and Budget.</label>
                                        <label style={myLink}>Learn More </label>
                                    </div>
                                </div>
                            </a>
                            <a href='#' className='col-lg-4 col-sm-6 pe-5 pb-5'>
                                <div className='row'>
                                    <div className='col-12 p-3' style={myCard}>
                                        <label className='mb-2' style={myHead}>Fraud & Scams</label>
                                        <label style={myPara}>Protect yourself from becoming a victim of car rental scams and fraud. Click the link below for common signs of people who may try to scam both you and Budget.</label>
                                        <label style={myLink}>Learn More </label>
                                    </div>
                                </div>
                            </a>
                            <a href='#' className='col-lg-4 col-sm-6 pe-5 pb-5'>
                                <div className='row'>
                                    <div className='col-12 p-3' style={myCard}>
                                        <label className='mb-2' style={myHead}>Fraud & Scams</label>
                                        <label style={myPara}>Protect yourself from becoming a victim of car rental scams and fraud. Click the link below for common signs of people who may try to scam both you and Budget.</label>
                                        <label style={myLink}>Learn More </label>
                                    </div>
                                </div>
                            </a>
                            <a href='#' className='col-lg-4 col-sm-6 pe-5 pb-5'>
                                <div className='row'>
                                    <div className='col-12 p-3' style={myCard}>
                                        <label className='mb-2' style={myHead}>Fraud & Scams</label>
                                        <label style={myPara}>Protect yourself from becoming a victim of car rental scams and fraud. Click the link below for common signs of people who may try to scam both you and Budget.</label>
                                        <label style={myLink}>Learn More </label>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>
                {/* End Slogan One */}
            </>
        )
    }
}