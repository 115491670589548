import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Discount from "../components/travel-agent/Discount";

const TravelAgentDiscount = () => {
  const breadcrumb = [
    "Programs",
    "Travel Agent Program",
    "Special Travel Agent Rates",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Discount />
      <FooterOne />
    </>
  );
};

export default TravelAgentDiscount;
