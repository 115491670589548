import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import AirlinesContent from "../components/miles-points/AirlinesContent";

const Airlines = () => {
  const breadcrumb = ["Programs", "Miles, Points & Partners", "Airlines"];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <AirlinesContent />
      <FooterOne />
    </>
  );
};

export default Airlines;
