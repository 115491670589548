import React, { useState } from "react";
import * as AiIcons from "react-icons/ai";
import { AiOutlineBars } from "react-icons/ai";
import { CgChevronDown, CgChevronLeft } from "react-icons/cg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SignModal from "./SignModal";

const menuData = [
  {
    title: "Reservations",
    path: "/",
    subNav: [
      {
        title: "Make a Reservation",
        path: "/makereservation",
      },
      {
        title: "View / Cancellation",
        path: "/view-cancel",
      },
    ],
  },
  {
    title: "Events and Specials",
    path: "#",
    subNav: [
      {
        title: "Promotion",
        path: "/international",
      },
    ],
  },
  {
    title: "Car Guides",
    path: "/carguides",
  },
  {
    title: "Services",
    path: "#",
    subNav: [
      {
        title: "Products",
        path: "/productservice/products",
      },
      {
        title: "Services",
        path: "/productservice/services",
      },
      {
        title: "Protections and Coverages",
        path: "/productservice/protection",
      },
      {
        title: "Programs",
        path: "/productservice/program",
      },
    ],
  },
  {
    title: "Locations",
    path: "#",
    subNav: [
      {
        title: "Find a Location",
        path: "/findlocation",
      },
      {
        title: "Top Airport Location",
        path: "/airportlocation",
      },
    ],
  },
];

const subMenuData = [
  {
    title: "Sign Up",
    path: "/register",
  },
  {
    title: "Custemer Service",
    path: "/customer-care",
  },
  {
    title: "FAQ",
    path: "https://faq.budget.co.uk",
  },
];

const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    background: #ff6f0f;
    color: #fff;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  color: #150b5c;
  font-weight: 700;
  font-size: 17px;
`;

const SidebarSubBottomLabel = styled.span`
  color: #ed5505;
  font-weight: 700;
  font-size: 14.4px;
`;

const DropdownLink = styled(Link)`
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  margin-bottom: 1em;
  &:hover,
  &:active {
    background: #ff6f0f;
    cursor: pointer;
    color: #fff;
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink
        to={process.env.PUBLIC_URL + `${item.path}`}
        onClick={item.subNav && showSubnav}
      >
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
              ? item.iconClosed
              : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((data, index) => (
          <DropdownLink
            to={process.env.PUBLIC_URL + `${data.path}`}
            key={index}
          >
            {item.icon}
            <SidebarLabel>{data.title}</SidebarLabel>
          </DropdownLink>
        ))}
      <hr />
    </>
  );
};

const SubBottomMenu = ({ item }) => {
  return (
    <>
      <SidebarLink to={process.env.PUBLIC_URL + `${item.path}`}>
        <div>
          {item.icon}
          <SidebarSubBottomLabel>{item.title}</SidebarSubBottomLabel>
        </div>
      </SidebarLink>
    </>
  );
};

const NavIcon = styled(Link)`
  color: #ff6f0f;
  font-size: 2rem;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const SidebarNav = styled.nav`
  background-color: #fff;
  width: 300px;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  top: 80px;
  right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 99999;
  border-top: 3px solid rgb(237, 85, 5);
  border-left: 3px solid #d0d0d0;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const MobileMenu = (props) => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const changeBtnMenu = () => props.setIsMobile(!props.btnIsMobile);
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <>
        <div className="d-xl-none d-flex justify-content-end align-items-center">
          <SignModal />
          <button
            className={`py-1 px-2 ms-4`}
            style={{
              background: `${props.btnIsMobile ? "#ed5505" : "#fff"}`,
              fontWeight: "400",
              color: `${props.btnIsMobile ? "white" : "#ed5505"}`,
              border: "2px solid #ed5505",
            }}
            onClick={() => {
              showSidebar();
              changeBtnMenu();
            }}
          >
            Menu
          </button>
        </div>
        {/* <NavIcon to="#" style={{ justifyContent: "flex-end" }}>
          <AiOutlineBars onClick={showSidebar} />
        </NavIcon> */}

        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <div
              className="mobile-nav__content"
              style={{ backgroundColor: "#fff" }}
            >
              {menuData.map((item, index) => (
                <SubMenu item={item} key={index} />
              ))}
              {subMenuData.map((item, index) => (
                <SubBottomMenu item={item} key={index} />
              ))}
            </div>
          </SidebarWrap>
        </SidebarNav>
      </>
    </>
  );
};

export default MobileMenu;
