import React from "react";
import DealsTwo from "../components/deals/DealsTwo";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import Title from "../Title";

const International = () => {
  Title("Our Promotion");
  return (
    <>
      <HeaderOne />
      <DealsTwo />
      <FooterOne />
    </>
  );
};

export default International;
