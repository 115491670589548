import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Header from "../common/header/Header";
import AirsDetails from "../components/miles-points/AirsDetails";
import { Link, useParams } from "react-router-dom";

const AirlinesDetails = () => {
  const params = useParams();
  let obj = {};
  if (params.details == "aero-new-zealand") {
    obj = {
      breadcrumbs: "Air New Zealand Airpoints",
      title: "Earn Air New Zealand Airpoints Dollars™",
      img: "airpoints.webp",
      description: (
        <>
          <p className="mb-3">
            Budget are partners in the Air New Zealand Airpoints™ Programme, so
            you can earn Airpoints Dollars™ when you rent from any one of our
            Budget locations in New Zealand, Australia or the Pacific, or from
            any Budget airport location worldwide, except Alaska.
          </p>
          <p className="mb-3">
            Simply quote your Airpoints number on your reservation or at the
            time of rental and you'll earn Airpoints Dollars on any qualifying
            rentals.*
          </p>
          <p className="mb-3">
            Airpoints members wanting to make car hire reservations can call
            Budget on 0800 283 444 or +64 9 526 3243.
          </p>
        </>
      ),
      rewards: (
        <p className="mb-3">
          Members earn 2.50 Airpoints Dollars per day's rental, on all
          qualifying rentals*.
        </p>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Rewards programme points will be awarded on eligible rentals.</li>
          <li>
            Airpoints Dollars may not be earned on staff, discount, free, net
            wholesale, certain corporate or travel industry rates.
          </li>
          <li>
            No earn on rentals redeemed using Airpoints Dollars. This includes
            any extensions made to a redemptions booking, whether paid for using
            Airpoints Dollars or covered by the renter.
          </li>
          <li>
            Earn Airpoints Dollars any one of our Budget locations in New
            Zealand, Australia or the Pacific, or from any Budget airport
            location worldwide, except Alaska.
          </li>
          <li>
            Only one points programme may be nominated per rental and the renter
            name must be the same as the membership name of that particular
            programme.
          </li>
          <li>
            Member must quote their Airpoints membership number when making a
            reservation, or show their membership card at the counter when
            collecting their vehicle. (If your membership number has already
            been linked to an Budget profile, then this is not necessary).
          </li>
          <li>
            Your airline flight ticket or boarding pass is not required to earn
            Airpoints Dollars with Budget.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <p className="mb-3">
          For more information or to enrol in the Air New Zealand Airpoints
          Programme, please visit the{" "}
          <Link className="linked">Air New Zealand website</Link>.
        </p>
      ),
    };
  } else if (params.details == "aer-lingus") {
    obj = {
      breadcrumbs: "Aer Lingus AerClub",
      title: "Aer Lingus AerClub",
      img: "",
      description: "",
      rewards: (
        <p className="mb-3">
          Collect 1 Avios per $1 spent on car rental when booking through the
          Aer Lingus Budget partnership
        </p>
      ),
      terms: (
        <div className="ps-5">
          <p>
            <b>Collecting Avios</b>
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>
              To qualify for standard and bonus Avios awards, rentals must
              include the Iberia Plus membership number of the first named
              driver (the "renter"). This AerClub card number must be given at
              time of reservation and the card shown at time of rental.
            </li>
            <li>
              Chauffeur Drive, van rentals, travel industry staff rates
              including Iberia and Avis/Budget employee programmes,
              replacement/insurance rates, selected association programmes, net
              rate programmes and rentals booked with online travel agents with
              the exception of iberia.com), brokers and price comparison sites
              do not qualify for Avios. Avios will not be awarded on any other
              Prestige rentals worldwide.
            </li>
            <li>
              Only rentals that qualify for standard Avios will qualify for any
              bonus Avios issued as a result of special promotions run by Budget
              from time to time.
            </li>
            <li>
              Consecutive or conjunctive rentals will not qualify for Avios
              awards – only the first qualifying rental in such situations will
              be awarded Avios.
            </li>
            <li>
              Avios can be collected on Budget car rental without taking a
              flight. The awarding of Avios may take up to 8 weeks from date of
              rental. All Avios issued are subject to AerClub terms and
              conditions. Renter must meet Budget age, driver and credit
              requirements.
            </li>
          </ul>
          <p>
            <b>Collect 1 Avios per $1 spent on Budget car rental</b>
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>
              Budget will award a standard ‘2 Avios per €1 spent’ (equivalent to
              at least 1 Avios per $1) on qualifying Budget rentals. If the
              rental is not paid in € Euros, then the currency will be changed
              to € Euros at the Budget Exchange rate applicable on date of
              rental commencement (the date the car is collected). The final
              rental value used to calculate the Avios award will exclude any
              charges for damage (actual damage charges or insurance excesses
              charged due to damage) and will exclude all taxes.
            </li>
            <li>
              Rentals made using Budget contracted (corporate/government)
              rates/BCDs, do not qualify for standard or bonus Avios. A maximum
              of 10,000 Avios will be awarded per qualifying rental.
            </li>
            <li>
              Post-rental, if any adjustments to the value of that rental are
              made (e.g. a refund is made by Budget Customer Services) then the
              appropriate Avios award adjustment may be credited or debited from
              the AerClub Member's account as necessary.
            </li>
          </ul>
        </div>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the AerClub program, please use
            the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link to="http://www.aerlingus.com/aerclub" className="linked">
              www.aerlingus.com/aerclub
            </Link>
          </p>
        </>
      ),
    };
  } else if (params.details == "aeroplan") {
    obj = {
      breadcrumbs: "Aeroplan",
      title: "Air Canada - Aeroplan®",
      img: "",
      description: (
        <>
          <p className="mb-3">
            Present your Aeroplan Membership number at the time of rental to
            receive 250 points, per qualified rental.
          </p>
          <p className="mb-3">
            And don’t forget the{" "}
            <Link className="linked">valuable discounts</Link> Budget offers
            Aeroplan members by simply providing Budget Customer Discount (BCD)
            # A258200.
          </p>
        </>
      ),
      rewards: "",
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            A qualifying vehicle rental is defined as one vehicle rental per
            day, per city, for all consecutive days a vehicle is rented-whether
            or not you renew the rental during those consecutive days.
          </li>
          <li>Available at participating locations worldwide.</li>
          <li>
            Aeroplan Members must provide their Aeroplan Number at the time of
            rental.
          </li>
          <li>
            Points may not be earned on free, tour or certain travel industry &
            BCD rates and may only be earned in one loyalty program per rental.
          </li>
          <li>
            Airline flight is not required in order to earn mileage credit.
          </li>
          <li>
            Renters must meet Budget age, driver and credit requirements.
            Minimum age may vary by location. An additional daily surcharge may
            apply for renters under 25 years old.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to become an Aeroplan Member, please use the
            information below.
          </p>
          <table width="50%" className="mb-3">
            <tbody>
              <tr>
                <td>Aeroplan Phone Number:</td>
                <td>1-800-361-5373</td>
              </tr>
              <tr>
                <td>Website:</td>
                <td>
                  <Link
                    to="https://www.aircanada.com/aeroplan"
                    className="linked"
                  >
                    www.aircanada.com/aeroplan
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: (
        <>
          <p style={{ fontSize: "14px" }}>
            ® Aeroplan is a registered trademark of Aeroplan Inc., used under
            license
          </p>
          <p className="mb-4" style={{ fontSize: "14px" }}>
            ® The Air Canada maple leaf logo is a registered trademark of Air
            Canada, used under license.
          </p>
        </>
      ),
    };
  } else if (params.details == "aero-alaska") {
    obj = {
      breadcrumbs: "Alaska Airlines Mileage Plan",
      title: "Earn Alaska Airlines Mileage Plan™ Miles",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            Mileage Plan members can earn miles on qualifying Budget rentals as
            follows:
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>
              1,250 miles per rental for Mileage Plan members with MVP Gold 75K
              or MVP Gold 100K status
            </li>
            <li>
              1,000 miles per rental for Mileage Plan members with MVP or MVP
              Gold status
            </li>
            <li>500 miles per rental for all other Mileage Plan members</li>
          </ul>
          <p className="mb-3">
            Plus, you can receive valuable discounts whenever you provide your
            Alaska Airlines Mileage Plan/Budget Customer Discount (BCD) number{" "}
            <b className="text-dark">U072200</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid at participating Budget airport locations worldwide</li>
          <li>
            Members must provide their Mileage Plan membership number at time of
            rental
          </li>
          <li>Airline flight is not required to earn miles</li>
          <li>
            Miles may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental
          </li>
        </ul>
      ),
      tax: (
        <p className="mb-3">
          A Frequent Traveler Program Surcharge or Excise Tax may be applied and
          will be assessed in connection with miles earned pursuant to a
          reservation. For rentals in the United States, Puerto Rico, St.
          Thomas, St. Croix and Canada, when the renter chooses to receive
          frequent flyer miles from Alaska Airlines, Budget will assess a
          frequent flyer surcharge equivalent to USD $1.00 per day. The amount
          assessed is collected by Budget to offset a portion of Budget's annual
          cost of participation in frequent flyer programs.
        </p>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Alaska Airlines Mileage
            Plan program, please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-4"
          >
            <tbody>
              <tr>
                <td valign="top">Mailing Address:</td>
                <td>
                  Alaska Airlines Mileage Plan
                  <br />
                  P.O. Box 24948
                  <br />
                  Seattle, WA 98124-0948
                </td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>1-800-426-0333</td>
              </tr>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link
                    to="https://www.alaskaair.com/content/mileage-plan"
                    className="linked"
                  >
                    www.alaskaair.com/mileageplan
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "aero-american") {
    obj = {
      breadcrumbs: "aero-american",
      title: "American Airlines AAdvantage®",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            American Airlines AAdvantage® members can earn at least 2 base miles
            for every dollar spent on all qualifying rentals at participating
            airport rental locations in the U.S., Canada, Europe, Middle East,
            Africa, Latin America and the Caribbean.
          </p>
          <p className="mb-3">Miles you’ll earn with each qualifying rental:</p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Earn 2 base miles per dollar spent – AAdvantage® members.</li>
            <li>
              Earn 3 base miles per dollar spent – Citi® / AAdvantage®
              cardmembers and AAdvantage® Aviator® Mastercard® cardmembers
              without AAdvantage® status.
            </li>
            <li>
              Earn 4 base miles per dollar spent –AAdvantage Platinum® and
              AAdvantage Gold® members.
            </li>
            <li>
              Earn 5 base miles per dollar spent – AAdvantage Platinum Pro®,
              AAdvantage Executive Platinum® members.
            </li>
          </ul>
          <p className="mb-3">
            Plus, you’ll earn 1 Loyalty Point for every AAdvantage® base mile
            earned. Also, AAdvantage® members can receive valuable discounts
            simply by providing the American AAdvantage® Budget Customer
            Discount (BCD) number <b className="text-dark">U072400</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid participating Budget airport locations worldwide.</li>
          <li>
            Members must provide their AAdvantage® membership number at the time
            of rental.
          </li>
          <li>Airline flight is not required to earn mileage credit.</li>
          <li>
            Miles may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the American Airlines
            AAdvantage® program, please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-4"
          >
            <tbody>
              <tr>
                <td valign="top">Mailing Address:</td>
                <td>
                  AAdvantage® Customer Service
                  <br />
                  PO Box 619623
                  <br />
                  DFW Airport, TX 75261-9623
                </td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>1-800-882-8880</td>
              </tr>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link
                    to="https://www.alaskaair.com/content/mileage-plan"
                    className="linked"
                  >
                    www.aa.com/aadvantage
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: (
        <>
          <p style={{ fontSize: "14px" }} className="mb-3">
            American Airlines reserves the right to change the AAdvantage®
            program and its terms and conditions at any time without notice, and
            to end the AAdvantage® program with six months notice. Any such
            changes may affect your ability to use the awards or mileage credits
            that you have accumulated. Unless specified, AAdvantage® base miles
            earned through this promotion/offer count towards status
            qualification or AAdvantage® Million MilerSM status. American
            Airlines is not responsible for products or services offered by
            other participating companies. For complete details about the
            AAdvantage® program, visit{" "}
            <Link className="linked">aa.com/aadvantage</Link>.
          </p>
          <p style={{ fontSize: "14px" }} className="mb-3">
            American Airlines, AAdvantage®, the Flight Symbol logo and
            AAdvantage® Million Miler are trademarks of American Airlines, Inc.
          </p>
        </>
      ),
    };
  } else if (params.details == "aero-life-miles") {
    obj = {
      breadcrumbs: "Avianca-Taca LifeMiles",
      title: "Earn Avianca-Taca LifeMiles",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            AMembers can earn 500 bonus miles for each qualifying Budget rental
            at participating airport locations worldwide. Plus, LifeMiles
            members can receive valuable discounts simply by providing your
            LifeMiles/Budget Customer Discount (BCD) number{" "}
            <b className="text-dark">R450700</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid at participating Budget airport locations worldwide</li>
          <li>
            Members must provide their LifeMiles membership number at time of
            rental
          </li>
          <li>Airline flight is not required to earn miles</li>
          <li>
            Miles may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the LifeMiles program, please
            use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link to="http://www.lifemiles.com" className="linked">
              www.lifemiles.com
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "airasia") {
    obj = {
      breadcrumbs: "BIG Loyality",
      title: "Earn BIG Points",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            Earn 250 BIG points per day, up to 2,500 BIG points per rental.
            Plus, you can enjoy valuable offers whenever you provide your BIG
            Loyalty/Budget Customer Discount (BCD) number{" "}
            <b className="text-dark">M001600</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Valid at participating Budget locations worldwide, exclude all
            locations in Alaska (USA).
          </li>
          <li>BIG Points will be awarded on eligible rentals.</li>
          <li>Airline flight is not required to earn point credit.</li>
          <li>
            Points may not be earned on staff, discount, free, net wholesale,
            certain corporate/government or travel industry rates.
          </li>
          <li>
            Only one points program may be nominated per rental and the main
            renter name must be the same as the membership name of that
            particular program.
          </li>
          <li>
            Member must quote their BIG Loyalty membership number when making a
            reservation, and/or show their membership card at the counter when
            collecting their vehicle. (If your membership number has already
            been linked to a Budget profile, then this is not necessary).
          </li>
          <li>
            Visit{" "}
            <Link className="linked">
              www.budgetinternational.com/airasiabig
            </Link>{" "}
            for more details.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the BIG Loyalty program, please
            use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link to="http://www.airasiabig.com" className="linked">
              www.airasiabig.com
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "british-airways") {
    obj = {
      breadcrumbs: "British Airways Executive Club",
      title: "British Airways Executive Club",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            Collect 1 Avios per $1 spent on car rental when booking through the
            British Airways Budget partnership
          </p>
        </>
      ),
      terms: (
        <>
          <div className="ps-5">
            <p>
              <b className="text-dark">Collecting Avios</b>
            </p>
            <ul className="similarCarOpt ps-5 mb-3">
              <li>
                To qualify for standard and bonus Avios awards, rentals must
                include the British Airways Executive Club membership number of
                the first named driver (the "renter"). This Executive Club card
                number must be given at time of reservation and the card shown
                at time of rental.
              </li>
              <li>
                Chauffeur Drive, van rentals, travel industry staff rates
                including BA and Avis/Budget employee programmes,
                replacement/insurance rates, selected association programmes,
                net rate programmes and rentals booked with online travel agents
                with the exception of ba.com), brokers and price comparison
                sites do not qualify for Avios. Avios will not be awarded on any
                Prestige rentals worldwide.
              </li>
              <li>
                Only rentals that qualify for standard Avios will qualify for
                any bonus Avios issued as a result of special promotions run by
                Budget from time to time.
              </li>
              <li>
                Consecutive or conjunctive rentals will not qualify for Avios
                awards – only the first qualifying rental in such situations
                will be awarded Avios.
              </li>
              <li>
                Avios can be collected on Budget car rental without taking a
                flight. The awarding of Avios may take up to 8 weeks from date
                of rental. All Avios issued are subject to Executive Club terms
                and conditions. Renter must meet Budget age, driver and credit
                requirements.
              </li>
            </ul>
          </div>
          <div className="ps-5">
            <p>
              <b className="text-dark">
                Collect 1 Avios per $1 spent on Budget car rental
              </b>
            </p>
            <ul className="similarCarOpt ps-5 mb-3">
              <li>
                Budget will award a standard ‘2 Avios per £1 spent’ (equivalent
                to at least 1 Avios per $1) on qualifying Budget rentals. If the
                rental is not paid in £ sterling, then the currency will be
                changed to £ sterling at the Budget Exchange rate applicable on
                date of rental commencement (the date the car is collected). The
                final rental value used to calculate the Avios award will
                exclude any charges for damage (actual damage charges or
                insurance excesses charged due to damage) and will exclude all
                taxes.
              </li>
              <li>
                Rentals made using Budget contracted (corporate/government)
                rates/BCDs, do not qualify for standard or bonus Avios. A
                maximum of 10,000 Avios will be awarded per qualifying rental.
              </li>
              <li>
                Post-rental, if any adjustments to the value of that rental are
                made (e.g. a refund is made by Budget Customer Services) then
                the appropriate Avios award adjustment may be credited or
                debited from the Executive Club Member's account as necessary.
              </li>
            </ul>
          </div>
        </>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the British Airways Executive
            Club program, please use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link
              to="http://www.britishairways.com/en-us/executive-club"
              className="linked"
            >
              www.britishairways.com/en-us/executive-club
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "cathay-pacific") {
    obj = {
      breadcrumbs: "Cathay Public",
      title: "Earn Asia Miles",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            Earn 500 Asia Miles per eligible rental. Plus, you can receive
            valuable enjoy whenever you provide your Cathay/Budget Customer
            Discount (BCD) number <b className="text-dark">D040700</b>
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Valid at participating Budget locations worldwide, exclude all
            locations in Alaska (USA).
          </li>
          <li>Miles will be awarded on eligible rentals.</li>
          <li>Airline flight is not required to earn miles.</li>
          <li>
            Miles may not be earned on staff, discount, free, net wholesale,
            certain corporate/government or travel industry rates.
          </li>
          <li>
            Only one miles program may be nominated per rental and the main
            renter name must be the same as the membership name of that
            particular program.
          </li>
          <li>
            Member must quote their Cathay membership number when making a
            reservation, and/or show their membership card at the counter when
            collecting their vehicle. (If your membership number has already
            been linked to a Budget profile, then this is not necessary).
          </li>
          <li>
            Visit{" "}
            <Link className="linked">
              one.budgetinternational.com/en_GB/BudgetCathayPacific
            </Link>{" "}
            for more details.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Cathay Membership program,
            please use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link to="www.cathaypacific.com" className="linked">
              www.cathaypacific.com
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "aero-copa") {
    obj = {
      breadcrumbs: "Copa Airlines ConnectMiles",
      title: "Earn Copa Airlines ConnectMiles",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            ConnectMiles members earn{" "}
            <b className="text-dark">100 points per rental day</b> (up to five
            days) on all qualifying rentals at participating airport rental
            locations in the U.S., Canada, Latin America and the Caribbean.
            Plus, you can receive valuable discounts whenever you provide your
            ConnectMiles Budget Customer Discount (BCD) number{" "}
            <b className="text-dark">T127300</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid at participating Budget airport locations worldwide.</li>
          <li>
            Members must present their ConnectMiles membership number at time of
            rental.
          </li>
          <li>Airline flight is not required to earn mileage credit.</li>
          <li>
            Miles may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
          <li>Frequent flyer surcharge of $1.00 per rental day apply.</li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Copa Airlines ConnectMiles
            program, please use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link to="connectmiles.copaair.com" className="linked">
              connectmiles.copaair.com
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "aero-el-al") {
    obj = {
      breadcrumbs: "Frequent Flyer Clubs",
      title: "Frequent Flyer Clubs Deals on Car Rentals",
      img: "",
      description: (
        <>
          <p className="mb-3">Earn 10 points!</p>
          <p className="mb-3">
            Members earn 10 points for each qualifying Budget rental at
            participating airport locations worldwide. Rental must be in
            conjunction with an El Al flight.
          </p>
          <Link className="d-inline-block btnCar mb-3">Rent Today</Link>
        </>
      ),
      rewards: "",
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Remember that you must fly on El Al and begin your rental within
            seven days of your flight. Simply present your ticket or boarding
            pass and El Al Frequent Flyer Club membership card at any
            participating Budget location worldwide at the time of rental.
          </li>
          <li>
            Earn miles at all corporate-operated and many participating
            licensee-owned airport locations worldwide. If you determine that
            your airport location is licensee-owned, please contact that
            location directly to find out if it's participating in the El Al
            Frequent Flyer program.
          </li>
          <li>Points may be earned on tour/wholesale rates.</li>
          <li>
            Points cannot be earned on travel industry or travel agent rates. In
            addition, members may only earn miles, points or credits with one
            frequent traveler program per rental. Also, points may not be earned
            on a rental if car rental award certificates are used.
          </li>
          <li>
            Frequent Flyer Club members also enjoy a 10% discount on standard
            rates, pay-on-arrival Budget World Travel Plan or Business Traveler
            rates when choosing Budget. An advance reservation is required and
            you must present your membership card at the time of rental.
          </li>
          <li>
            Refer to your El Al Frequent Flyer Clubs member program guide for
            details.
          </li>
        </ul>
      ),
      tax: "",
      partner: "",
      notes: "",
    };
  } else if (params.details == "emirates") {
    obj = {
      breadcrumbs: "Emirates Skywards",
      title: "Earn Emirates Skywards Miles",
      img: "",
      description: "",
      rewards: (
        <>
          <p>
            Emirates Skywards members can earn up to 7 Skywards Miles per USD 1
            spent based on membership status. Earn additional 1 mile per USD 1
            spent when paying with an Emirates Skywards co-branded credit card.
          </p>
          <table
            cellpadding="1"
            cellspacing="0"
            border="1"
            width="70%"
            align="center"
            className="tablePrivacy my-3"
          >
            <tbody>
              <tr>
                <td>
                  <b>Membership Tier</b>
                </td>
                <td>
                  <b>SkywardsMiles earned per USD spent</b>
                </td>
                <td>
                  <b>
                    Skywards Miles earned per USD spent using the Emirates
                    Skywards co-branded credit card
                  </b>
                </td>
              </tr>
              <tr>
                <td>Blue</td>
                <td>4</td>
                <td>5</td>
              </tr>
              <tr>
                <td>Silver</td>
                <td>5</td>
                <td>6</td>
              </tr>
              <tr>
                <td>Gold</td>
                <td>6</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Platinum</td>
                <td>7</td>
                <td>8</td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Valid participating Budget locations worldwide, exclude Alaska.
          </li>
          <li>
            Members must provide their Emirates Skywards membership number at
            the time of rental.
          </li>
          <li>Airline flight is not required to earn point credit.</li>
          <li>
            Miles will not be awarded on contracted rates, employee rates,
            Airline employee rates, Government rates, Complimentary or Free
            rental, Tour Operator rates or any other net rates.
          </li>
          <li>
            Miles may only be earned on one Frequent Flyer Program per rental.
          </li>
          <li>
            Visit{" "}
            <Link className="linked">www.budgetinternational.com/skywards</Link>{" "}
            for more details and full terms and conditions.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Emirates Skywards program,
            please use the information below.
          </p>
          <p className="mb-4">
            Web Site: <Link className="linked">www.emirates.com/skywards</Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "frontier") {
    obj = {
      breadcrumbs: "FRONTIER Airlines FRONTIER Miles",
      title: "Earn FRONTIER Airlines FRONTIER Miles",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            Earn 50 FRONTIER Miles miles per rental day on all qualifying
            rentals or 500 miles on qualifying rentals of 5 or more days. Plus,
            you can receive valuable discounts whenever you provide your
            FRONTIER Miles number/Budget Customer Discount (BCD) number{" "}
            <b className="text-dark">D446200</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid participating Budget airport locations worldwide.</li>
          <li>
            Members must provide their FRONTIER Miles membership number at the
            time of rental.
          </li>
          <li>Airline flight is not required to earn point credit.</li>
          <li>
            Points may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Southwest Rapid Rewards
            program, please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-4"
          >
            <tbody>
              <tr>
                <td valign="top">Mailing Address:</td>
                <td>
                  FRONTIER Miles
                  <br />
                  7001 Tower Road
                  <br />
                  Denver, CO 80249
                </td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>(801) 401-9000</td>
              </tr>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link
                    to="www.flyfrontier.com/myfrontier/frontier-miles/"
                    className="linked"
                  >
                    www.flyfrontier.com/myfrontier/frontier-miles/
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "aero-hawaiian") {
    obj = {
      breadcrumbs: "Hawaiian Airlines HawaiianMiles",
      title: "Earn Hawaiian Airlines HawaiianMiles",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            Earn a minimum of 250 HawaiianMiles per rental on all qualifying
            rentals.
          </p>
          <p className="mb-3">
            <b className="text-dark">HawaiianMiles Status</b>
          </p>
          <table
            cellpadding="3"
            cellspacing="0"
            border="0"
            width="400"
            className="mb-3"
          >
            <tbody>
              <tr>
                <td>HawaiianMiles Member</td>
                <td>250 miles / rental</td>
              </tr>
              <tr>
                <td>Pualani Gold</td>
                <td>500 miles / rental</td>
              </tr>
              <tr>
                <td>Pualani Platinum</td>
                <td>750 miles / rental</td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid at participating Budget locations worldwide..</li>
          <li>
            Members must provide their HawaiianMiles membership number at the
            time of rental.
          </li>
          <li>Airlines flight not required to earn mileage credit.</li>
          <li>
            Miles may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Hawaiian Airlines
            HawaiianMiles program, please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-4"
          >
            <tbody>
              <tr>
                <td valign="top">Phone Number:</td>
                <td>1-877-426-4537</td>
              </tr>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link className="linked">
                    www.hawaiianairlines.com/hawaiianmiles
                  </Link>
                </td>
              </tr>
              <tr>
                <td>Contact Us/Ask a Question:</td>
                <td>
                  <Link to="www.hawaiianairlines.com/HMSC" className="linked">
                    www.hawaiianairlines.com/HMSC
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "iberia-plus") {
    obj = {
      breadcrumbs: "Iberia Plus",
      title: "Iberia Plus",
      img: "",
      description: "",
      rewards: (
        <p className="mb-3">
          Collect 1 Avios per $1 spent on car rental when booking through the
          Iberia Budget partnership
        </p>
      ),
      terms: (
        <>
          <div className="ps-5">
            <p>
              <b className="text-dark">Collecting Avios</b>
            </p>
            <ul className="similarCarOpt ps-5 mb-3">
              <li>
                To qualify for standard and bonus Avios awards, rentals must
                include the Iberia Plus membership number of the first named
                driver (the "renter"). This Executive Club card number must be
                given at time of reservation and the card shown at time of
                rental.
              </li>
              <li>
                Chauffeur Drive, van rentals, travel industry staff rates
                including Iberia and Avis/Budget employee programmes,
                replacement/insurance rates, selected association programmes,
                net rate programmes and rentals booked with online travel agents
                with the exception of iberia.com), brokers and price comparison
                sites do not qualify for Avios. Avios will not be awarded on any
                other Prestige rentals worldwide.
              </li>
              <li>
                Only rentals that qualify for standard Avios will qualify for
                any bonus Avios issued as a result of special promotions run by
                Budget from time to time.
              </li>
              <li>
                Consecutive or conjunctive rentals will not qualify for Avios
                awards – only the first qualifying rental in such situations
                will be awarded Avios.
              </li>
              <li>
                Avios can be collected on Budget car rental without taking a
                flight. The awarding of Avios may take up to 8 weeks from date
                of rental. All Avios issued are subject to Iberia Plus terms and
                conditions. Renter must meet Budget age, driver and credit
                requirements.
              </li>
            </ul>
          </div>
          <div className="ps-5">
            <p>
              <b className="text-dark">
                Collect 1 Avios per $1 spent on Budget car rental
              </b>
            </p>
            <ul className="similarCarOpt ps-5 mb-3">
              <li>
                Budget will award a standard ‘2 Avios per €1 spent’ (equivalent
                to at least 1 Avios per $1) on qualifying Budget rentals. If the
                rental is not paid in € Euros, then the currency will be changed
                to € Euros at the Budget Exchange rate applicable on date of
                rental commencement (the date the car is collected). The final
                rental value used to calculate the Avios award will exclude any
                charges for damage (actual damage charges or insurance excesses
                charged due to damage) and will exclude all taxes.
              </li>
              <li>
                Rentals made using Budget contracted (corporate/government)
                rates/BCDs, do not qualify for standard or bonus Avios. A
                maximum of 10,000 Avios will be awarded per qualifying rental.
              </li>
              <li>
                Post-rental, if any adjustments to the value of that rental are
                made (e.g. a refund is made by Budget Customer Services) then
                the appropriate Avios award adjustment may be credited or
                debited from the Executive Club Member's account as necessary.
              </li>
            </ul>
          </div>
        </>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Hawaiian Airlines
            HawaiianMiles program, please use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link
              to="http://www.iberia.com/us/all-iberiaplus"
              className="linked"
            >
              www.iberia.com/us/all-iberiaplus
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "japan-airlines") {
    obj = {
      breadcrumbs: "JAL Mileage Bank",
      title: "Earn JAL Mileage Bank Miles",
      img: "",
      description: "",
      rewards: (
        <p className="mb-3">
          Earn 50 miles per day, up to 250 miles per eligible rental. Plus, you
          can enjoy valuable offers whenever you provide your JAL Mileage
          Bank/Budget Customer Discount (BCD) number{" "}
          <b className="text-dark">D609500</b>
        </p>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Valid at participating Budget locations worldwide, exclude all
            locations in Alaska (USA).
          </li>
          <li>Miles will be awarded on eligible rentals.</li>
          <li>Airline flight is not required to earn miles.</li>
          <li>
            Miles may not be earned on staff, discount, free, net wholesale,
            certain corporate/government or travel industry rates.
          </li>
          <li>
            Only one miles program may be nominated per rental and the main
            renter name must be the same as the membership name of that
            particular program.
          </li>
          <li>
            Member must quote their JAL Mileage Bank membership number when
            making a reservation, and/or show their membership card at the
            counter when collecting their vehicle. (If your membership number
            has already been linked to a Budget profile, then this is not
            necessary).
          </li>
          <li>
            Visit{" "}
            <Link className="linked">www.budgetinternational.com/jal</Link> for
            more details.
          </li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Hawaiian Airlines
            HawaiianMiles program, please use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link to="http://	www.jal.co.jp/en/jmb/" className="linked">
              www.jal.co.jp/en/jmb/
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "jet-blue") {
    obj = {
      breadcrumbs: "JetBlue TrueBlue",
      title: "Earn JetBlue TrueBlue Points",
      img: "",
      description: "",
      rewards: (
        <p className="mb-3">
          TrueBlue members earn{" "}
          <b className="text-dark">100 points per rental day</b> (up to five
          days) on all qualifying rentals at participating airport rental
          locations in the U.S., Canada, Latin America and the Caribbean. Plus,
          you can receive valuable discounts whenever you provide your TrueBlue
          Budget Customer Discount (BCD) number{" "}
          <b className="text-dark">R344900</b>.
        </p>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid participating Budget airport locations worldwide.</li>
          <li>
            Members must provide their TrueBlue membership number at the time of
            rental.
          </li>
          <li>Airline flight is not required to earn point credit.</li>
          <li>
            Points may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
          <li>A Frequent flyer surcharge of $1.00 USD per day will apply.</li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <table
          cellpadding="5"
          cellspacing="5"
          border="0"
          width="50%"
          className="mb-4"
        >
          <tbody>
            <tr>
              <td valign="top">Mailing Address::</td>
              <td>
                {" "}
                JetBlue Airways Corp. <br />
                27-01 Queens Plaza North <br />
                Long Island City, NY 11101
              </td>
            </tr>
            <tr>
              <td>Web Site:</td>
              <td>
                <Link className="linked">trueblue.jetblue.com</Link>
              </td>
            </tr>
          </tbody>
        </table>
      ),
      notes: "",
    };
  } else if (params.details == "malaysia-airlines") {
    obj = {
      breadcrumbs: "Malaysia Airlines Enrich",
      title: "Earn Malaysia Airlines Enrich Miles",
      img: "",
      description: "",
      rewards: (
        <p className="mb-3">
          Earn 500 Enrich miles per eligible rental. Plus, you can enjoy
          valuable offers whenever you provide your Enrich/Budget Customer
          Discount (BCD) number <b className="text-dark">D040800</b>.
        </p>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Valid at participating Budget locations worldwide, exclude all
            locations in Alaska (USA).
          </li>
          <li>Miles will be awarded on eligible rentals.</li>
          <li>Airline flight is not required to earn miles.</li>
          <li>
            Miles may not be earned on staff, discount, free, net wholesale,
            certain corporate/government or travel industry rates.
          </li>
          <li>
            Only one miles program may be nominated per rental and the main
            renter name must be the same as the membership name of that
            particular program.
          </li>
          <li>
            Member must quote their Malaysia Airlines Enrich membership number
            when making a reservation, and/or show their membership card at the
            counter when collecting their vehicle. (If your membership number
            has already been linked to a Budget profile, then this is not
            necessary).
          </li>
          <li>
            Visit{" "}
            <Link className="linked">
              www.budgetinternational.com/malaysiaairlines
            </Link>{" "}
            for more details.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Malaysia Airlines Enrich
            program, please use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link
              to="http://www.malaysiaairlines.com/my/en/enrich.html"
              className="linked"
            >
              www.malaysiaairlines.com/my/en/enrich.html
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "qatar") {
    obj = {
      breadcrumbs: "Qatar Airways Privilege Club",
      title: "Earn Qatar Airways Privilege Club Qmiles",
      img: "",
      description: "",
      rewards: (
        <p className="mb-3">Earn 500 Privilege Club Qmiles per rental.</p>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid participating Budget airport locations worldwide.</li>
          <li>
            Members must provide their Qatar Airways Privilege Club membership
            number at the time of rental.
          </li>
          <li>Airline flight is not required to earn point credit.</li>
          <li>
            Points may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Qatar Airways Privilege
            Club program, please use the information below.
          </p>
          <p className="mb-5">
            Web Site:
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </b>
            <Link to="http://www.qmiles.com" className="linked">
              www.qmiles.com
            </Link>
          </p>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "saudia") {
    obj = {
      breadcrumbs: "Alfursan",
      title: "Alfursan Deals on Car Rentals",
      img: "",
      description: (
        <>
          <p className="mb-3">Earn up to 600 miles! </p>
          <p className="mb-3">
            Members earn up to 600 miles for each qualifying Budget rental at
            participating airport locations worldwide. No requirement to fly on
            Saudia or partner airline
          </p>
          <Link
            to={`${process.env.PUBLIC_URL}/makereservation`}
            className="d-inline-block btnCar mb-5"
          >
            Rent Now
          </Link>
          <p className="mb-3">Alfursan members receive:</p>
          <p className="mb-3">
            <b className="text-dark">
              Standard Rates (rack, standard, Go!Budget, International Traveler
              and other standard retail rates)
            </b>
          </p>
          <table
            border="0"
            cellspacing="0"
            cellpadding="0"
            width="100%"
            className="tableSaudia mb-3"
          >
            <tbody>
              <tr style={{ background: "rgb(204,204,255)" }}>
                <td>Membership Level</td>
                <td>Rentals 1-4 Days</td>
                <td>Rentals 5+ Days</td>
              </tr>
              <tr>
                <td>Blue</td>
                <td>500</td>
                <td>600</td>
              </tr>
              <tr style={{ background: "rgb(204,204,255)" }}>
                <td valign="top">Silver</td>
                <td valign="top">600</td>
                <td valign="top">600</td>
              </tr>
              <tr>
                <td valign="top">Gold</td>
                <td valign="top">600</td>
                <td valign="top">600</td>
              </tr>
            </tbody>
          </table>
          <p className="mb-3">
            <b className="text-dark">
              Corporate Rates (CorpRate, Business Club)
            </b>
          </p>
          <table
            border="0"
            cellspacing="0"
            cellpadding="0"
            width="100%"
            className="tableSaudia mb-3"
          >
            <tbody>
              <tr style={{ background: "rgb(204,204,255)" }}>
                <td>Membership Level</td>
                <td>Rentals 1-4 Days</td>
                <td>Rentals 5+ Days</td>
              </tr>
              <tr>
                <td>Blue</td>
                <td>250</td>
                <td>300</td>
              </tr>
              <tr style={{ background: "rgb(204,204,255)" }}>
                <td valign="top">Silver</td>
                <td valign="top">300</td>
                <td valign="top">300</td>
              </tr>
              <tr>
                <td valign="top">Gold</td>
                <td valign="top">300</td>
                <td valign="top">300</td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      rewards: "",
      terms: (
        <ul className="similarCarOpt ps-5 mb-5">
          <li>
            No Saudia flight, ticket or boarding pass is required to earn miles.
            Simply present your Alfursan membership card at any participating
            Budget location at the time of rental.
          </li>
          <li>
            Earn miles at all corporate-operated and many participating
            licensee-owned airport locations worldwide. If you determine that
            your location is licensee-owned, please contact that location
            directly to find out if it's participating in the Alfursan Program.
          </li>
          <li>
            Miles cannot be earned on tour/wholesale, travel industry,
            promotional and discounted rates. In addition, members may earn
            miles, points or credits only with one frequent traveler program per
            rental. Also, miles cannot be earned on a rental if a car rental
            award certificate/voucher is being used.
          </li>
          <li>
            In Saudi Arabia, only members may have the option of a 30% discount
            as an alternative to a miles award when renting on standard rates.
          </li>
          <li>Refer to your Alfursan Membership guide for details.</li>
        </ul>
      ),
      tax: "",
      partner: "",
      notes: "",
    };
  } else if (params.details == "singapore-airlines") {
    obj = {
      breadcrumbs: "Singapore Airlines KrisFlyer",
      title: "Earn Singapore Airlines KrisFlyer Miles",
      img: "",
      description: (
        <>
          <p className="mb-3">
            Earn 500 KrisFlyer miles per eligible rental. Plus, you can enjoy
            valuable offers whenever you provide your KrisFlyer/Budget Customer
            Discount (BCD) number <b className="text-dark">D041000</b>.
          </p>
        </>
      ),
      rewards: "",
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Valid at participating Budget locations worldwide, exclude all
            locations in Alaska (USA).
          </li>
          <li>Miles will be awarded on eligible rentals.</li>
          <li>Airline flight is not required to earn miles.</li>
          <li>
            Miles may not be earned on staff, discount, free, net wholesale,
            certain corporate/government or travel industry rates.
          </li>
          <li>
            Only one miles program may be nominated per rental and the main
            renter name must be the same as the membership name of that
            particular program.
          </li>
          <li>
            Member must quote their Singapore Airlines KrisFlyer membership
            number when making a reservation, and/or show their membership card
            at the counter when collecting their vehicle. (If your membership
            number has already been linked to a Budget profile, then this is not
            necessary).
          </li>
          <li>
            Visit{" "}
            <Link className="linked">
              www.budgetinternational.com/singaporeairlines
            </Link>{" "}
            for more details.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Singapore Airlines
            KrisFlyer program, please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-5"
          >
            <tbody>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link
                    to="https://www.singaporeair.com/en_UK/us/ppsclub-krisflyer/"
                    className="linked"
                  >
                    www.singaporeair.com/en_UK/us/ppsclub-krisflyer
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "spirit") {
    obj = {
      breadcrumbs: "Spirit Airlines FREE SPIRIT",
      title: "Earn Spirit Airlines FREE SPIRIT Miles",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            FREE SPIRIT members can earn 50 FREE SPIRIT miles on rentals 4 days
            or less <b className="text-dark">OR</b> 500 FREE SPIRIT miles on
            rentals over 5 days, at participating Budget locations in the U.S.,
            Canada, Latin America and the Caribbean. Plus, you can save up to
            35% off whenever you provide your FREE SPIRIT/Budget Customer
            Discount (BCD) number <b className="text-dark">U109700</b>. Always
            mention your BCD and FREE SPIRIT member number at time of
            reservation to receive your points and discount.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Valid at participating Budget airport locations in the U.S., Canada,
            Latin America and the Caribbean.
          </li>
          <li>
            Members must provide their FREE SPIRIT membership number at time of
            rental.
          </li>
          <li>Airline flight is not required to earn miles.</li>
          <li>
            Miles may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Spirit Airlines FREE SPIRIT
            program, please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-3"
          >
            <tbody>
              <tr>
                <td valign="top">Mailing Address:</td>
                <td>
                  Spirit Airlines FREE SPIRIT
                  <br />
                  2800 Executive Way
                  <br />
                  Miramar, FL 33025
                </td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>1-801-401-2222</td>
              </tr>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link className="linked" to="https://www.spirit.com/">
                    www.spirit.com
                  </Link>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "south-west") {
    obj = {
      breadcrumbs: "Southwest Rapid Rewards",
      title: "Earn Southwest Airlines Rapid Rewards Points",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            Earn 600 Rapid Rewards Points per rental. Plus, you can receive
            valuable discounts whenever you provide your Southwest Airlines
            Rapid Rewards/Budget Customer Discount (BCD) number{" "}
            <b className="text-dark">U072700</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid participating Budget airport locations worldwide.</li>
          <li>
            Members must provide their Rapid Rewards membership number at the
            time of rental.
          </li>
          <li>Airline flight is not required to earn point credit.</li>
          <li>
            Points may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
          <li>A Frequent flyer surcharge of $1.00 USD per day will apply.</li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles from the following U.S. airline and hotel
            programs, Budget will assess a frequent flyer surcharge equivalent
            to USD $0.75 per day (except $1.00 per day for American Airlines,
            Copa Airlines, JetBlue Airways and Southwest Airlines). The amount
            assessed is collected by Budget to offset a portion of Budget's
            annual cost of participation in frequent flyer programs.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>Alaska Airlines</li>
            <li>American Airlines</li>
            <li>Best Western Hotels & Resorts</li>
            <li>Choice Hotels</li>
            <li>Copa Airlines</li>
            <li>FRONTIER Airlines</li>
            <li>Hawaiian Airlines</li>
            <li>Hyatt Hotels</li>
            <li>JetBlue Airways</li>
            <li>La Quinta Inns & Suites</li>
            <li>Radisson Hotels</li>
            <li>Southwest Airlines</li>
            <li>Spirit Airlines</li>
            <li>Wyndham Hotels & Resorts</li>
          </ul>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Southwest Rapid Rewards
            program, please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-3"
          >
            <tbody>
              <tr>
                <td valign="top">Mailing Address:</td>
                <td>
                  Southwest Airlines Rapid Rewards
                  <br />
                  P.O. Box 36657
                  <br />
                  Dallas, TX 75235
                </td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>
                  (800) 445-5764 for Rapid Rewards Customer Service
                  <br />
                  (800) 248-4377 for Reservations
                </td>
              </tr>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link
                    className="linked"
                    to="https://www.southwest.com/rapidrewards"
                  >
                    www.southwest.com/rapidrewards
                  </Link>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "thai") {
    obj = {
      breadcrumbs: "Thai Airways Royal Orchid Plus",
      title: "Earn Thai Airways Royal Orchid Plus Miles",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            Earn 500 Royal Orchid Plus miles per rental. Plus, you can receive
            valuable discounts whenever you provide your Thai Airways Royal
            Orchid Plus/Budget Customer Discount (BCD) number{" "}
            <b className="text-dark">D625800</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid participating Budget airport locations worldwide.</li>
          <li>
            Members must provide their Royal Orchid Plus membership number at
            the time of rental.
          </li>
          <li>Airline flight is not required to earn point credit.</li>
          <li>
            Points may not be earned on free, tour or certain travel industry
            rates and may only be earned in one frequent traveler program per
            rental.
          </li>
        </ul>
      ),
      tax: "",
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the Thai Airways Royal Orchid
            Plus program, please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-3"
          >
            <tbody>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link className="linked" to="https://www.thaiairways.com">
                    www.thaiairways.com
                  </Link>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: "",
    };
  } else if (params.details == "united-airlines") {
    obj = {
      breadcrumbs: "United Airlines",
      title: "United MileagePlus",
      img: "",
      description: "",
      rewards: (
        <>
          <p className="mb-3">
            United MileagePlus members can earn miles on completed Budget
            rentals at participating airport and off-airport locations
            worldwide.
          </p>
          <ul className="similarCarOpt ps-5 mb-3">
            <li>General members can earn 500 base miles per rental</li>
            <li>Chase card members can earn 750 base miles per rental</li>
            <li>
              Premier® Silver and Premier Gold members can earn 1,000 base miles
              per rental
            </li>
            <li>
              Premier Platinum and Premier 1K® members earn 1,250 base miles per
              rental
            </li>
          </ul>
          <p>
            Plus, you can receive valuable discounts whenever you provide your
            MileagePlus Budget Customer Discount (BCD) number{" "}
            <b className="text-dark">B204900</b>.
          </p>
        </>
      ),
      terms: (
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Valid participating Budget airport locations worldwide.</li>
          <li>
            Members must provide their MileagePlus membership number at the time
            of rental.
          </li>
          <li>Airline flight is not required to earn point credit.</li>
          <li>
            Miles may not be earned on free, tour, wholesale or certain travel
            industry rates and may only be earned in one frequent traveler
            program per rental.
          </li>
        </ul>
      ),
      tax: (
        <>
          <p className="mb-3">
            A Frequent Traveler Program Surcharge or Excise Tax may be applied
            and will be assessed in connection with miles earned pursuant to a
            reservation. For rentals in the United States, Puerto Rico, St.
            Thomas, St. Croix and Canada, when the renter chooses to receive
            frequent flyer miles in the United MileagePlus program, Budget will
            assess a frequent flyer surcharge equivalent to USD $1.50 per rental
            day. The amount assessed is collected by Budget to offset a portion
            of Budget's annual cost of participation in frequent flyer programs.
          </p>
        </>
      ),
      partner: (
        <>
          <p className="mb-3">
            For more information or to enroll in the United MileagePlus program,
            please use the information below.
          </p>
          <table
            cellpadding="5"
            cellspacing="5"
            border="0"
            width="50%"
            className="mb-4"
          >
            <tbody>
              <tr>
                <td valign="top">Mailing Address:</td>
                <td>
                  United Airlines, Inc.
                  <br />
                  P.O. Box 06649
                  <br />
                  Chicago, IL 60606-0649
                </td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>1-800-421-4655</td>
              </tr>
              <tr>
                <td>Web Site:</td>
                <td>
                  <Link className="linked" href="https://www.united.com/join">
                    united.com/join
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
      notes: "",
    };
  }

  const breadcrumb = [
    "Programs",
    "Miles, Points & Partners",
    "Airlines",
    obj.breadcrumbs,
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <AirsDetails obj={obj} />
      <FooterOne />
    </>
  );
};

export default AirlinesDetails;
