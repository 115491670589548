import React from "react";

const RoadsideAssitancePlus = () => {
  return (
    <div className="container">
      <hr />
      <h3>Roadside Assistance Plus</h3>
      <b className="mt-3">24/7 breakdown and roadside assistance</b>
      <p className="mt-3 mb-3">
        Extended Roadside Assistance or Roadside Assistance Plus provides
        protection on recovery assistance costs if you experience a
        non-mechanical breakdown during your journey. You also don't have to
        worry about call-out charges.
      </p>
      <b className="mt-3">
        What is Extended Roadside Assistance/ Roadside Assistance Plus?
      </b>
      <p className="mt-3 mb-3">
        You get extra peace of mind when you add Roadside Assistance Plus to
        your rental. You're instantly covered for common problems like flat
        batteries and locked-in keys - and you don't pay any call out charges.
      </p>
      <b className="mt-3">What does it include?</b>
      <p className="mt-3">
        In addition to the standard breakdown assistance included in your
        rental, you will also be covered for common non-mechanical problems
        including:
      </p>
      <p className="">
        • Tyre Assistance - we'll replace a flat or damaged tyre with the spare
        or give you a tow. (Please note that the renter might incur the cost of
        the tire repair or replacement, depending upon the circumstances.)
      </p>
      <p className="">
        • Flat Battery - we'll come and jump start your vehicle.
      </p>
      <p className="">
        • Lock Out Service - if you're locked out, we'll help you get in.
      </p>
      <p className="">
        • Fuel Delivery - if you run out, we'll deliver fuel to your location.
        Charges may apply.
      </p>
      <p className="mb-3">
        • Lost Key - We'll come and give you a replacement, should you lose your
        key
      </p>
      <b className="mt-3">Additional Information</b>
      <p className="mt-3">
        Roadside Assistance Plus cover is available at all Budget stations
        across the UK and Europe. Extended Roadside Assistance is available at
        all participating Budget locations in the U.S., Canada, Puerto Rico and
        U.S. Virgin Islands. The daily service charge is vary by rental location
        and subject to change. Prohibited use of the vehicle will void this
        option. In such a case, emergency road hazard assistance will be
        delivered, but standard charges for services such as lost keys, fuel
        delivery, etc. may apply.
      </p>
      <p className="mt-3">
        Similar Roadside Assistance services are available in all other Budget
        rental locations at additional charges.
      </p>
      <p className="mt-3">
        Every Budget vehicle is automatically covered for 24-hour basic not-at
        fault mechanical breakdown assistance. If you are on the road and need
        help please call the phone number provided on rental Agreement and we
        will get you back on the road as quickly as possible. Charges may apply
        for situation requiring Roadside assistance where you are at fault.
      </p>
      <p className="mt-3">
        Please note: the basic level of roadside assistance outlined above is
        based on rentals collected from corporate Budget locations. Assistance
        for rentals from licensee locations may vary.
      </p>
      <p className="mt-3">
        Please note that Extended Roadside Assistance/ Roadside Assistance Plus
        cost will vary from country to country. This can be purchased at the
        counter upon car pick-up.
      </p>
      <hr />
    </div>
  );
};

export default RoadsideAssitancePlus;
