import React from "react";
import { Link } from "react-router-dom";

export default class DP extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };

    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link>Products and Services</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link>Protection and Coverages</Link>
                    </li>
                    <li className="breadcrumb-item myLink" aria-current="page">
                      {this.props.data}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container py-5">
            <div className="row">
              {this.props.data === "Loss Damage Waiver (LDW)" ? (
                <div className="col-sm-12">
                  <h1>{this.props.data}</h1>
                  {/* LDW */}
                  <p className="mb-4">
                    This is an option that relieves you of financial
                    responsibility if your rental car is damaged during the
                    rental period. You will, however, have to pay an excess.
                    Also known as Collision Damage Waiver (CDW) in some
                    countries.
                  </p>
                  <b>What is covered?</b>
                  <p className="mb-4">
                    You are covered for the cost of repairing the Budget vehicle
                    if it is damaged. Please be aware you will have to pay a
                    fixed amount towards the repair costs. This is known as the
                    excess. This applies regardless of whether you were at
                    fault. If we can recover these costs from a responsible
                    third party we will reimburse you.
                  </p>
                  <b>What is covered?</b>
                  <ul>
                    <li>
                      - Damage caused intentionally or by gross negligence.
                    </li>
                    <li>- Damage caused by failure to use correct fuel.</li>
                    <li>
                      - Damage caused as a result of a breach of the rental
                      agreement.{" "}
                    </li>
                    <li>- Damage caused to the windscreen.</li>
                    <li>- Damage caused to the tyres.</li>
                  </ul>
                  <p className="mb-4">
                    Super Damage Waiver (SCDW) is an excess reduction product
                    which reduces the excess to zero except for vans in case of
                    accidental damage to the rented car. The price depends on
                    the vehicle and location and can be purchased at counter.
                    Terms and conditions apply.
                  </p>
                  <b>For rentals in the USA and Canada</b>
                  <p className="mb-4">
                    If you accept full LDW by your initials on the rental
                    document at the additional daily rate, for each full or
                    partial day that the car is rented to you and the car is
                    operated in accordance with the Agreement, we assume
                    responsibility for the loss of or damage to the car except,
                    if permitted by law, for lost, damaged or stolen keys or
                    remote entry devices, towing or tire services, unless
                    related to an accident, or recovery of the car if stolen
                    (except in the state of Alaska) and except for your amount
                    of "responsibility", if any, specified on the rental
                    document.{" "}
                  </p>
                  <p className="mb-4">
                    If you do not accept LDW, you owe for all loss or damage to
                    the car. Loss and damage are described in the rental jacket.
                    You acknowledge you have been advised that your own
                    insurance may cover loss or damage to the car. You also
                    acknowledge reading the notice on loss damage shown on the
                    rental document, or in these Terms and Conditions, or in a
                    separate notice form.
                  </p>
                  <p className="mb-4">
                    In Canada, if you select LDW and your rental vehicle is
                    damaged or becomes a loss, you may incur a deductible due to
                    the loss or damage. This varies, based on the type of
                    vehicle you rented, and will be specified in your rental
                    agreement. Currently, this amount ranges from $300 CDN to
                    $5,000 CDN.
                  </p>
                  <p className="mb-4">
                    For full details about LDW, please refer to rental
                    location’s terms and conditions.
                  </p>
                  {/* LDW */}
                </div>
              ) : this.props.data === "Theft Protection (TP)" ? (
                <div className="col-sm-12">
                  <h1>{this.props.data}</h1>

                  {/* TP */}
                  <p className="mb-4">
                    If your rental vehicle is stolen, this will cover the cost
                    of replacing it (or parts of it). You will, however, have to
                    pay an excess.
                  </p>
                  <b>What is covered?</b>
                  <p className="mb-4">
                    You are covered for the cost of replacing the Budget vehicle
                    (or parts of it) if it gets stolen. Please be aware you will
                    have to pay a fixed amount towards the replacement cost.
                    This is known as the excess.
                  </p>
                  <b>What is covered?</b>
                  <p className="mb-4">
                    Negligence regarding loading of a vehicle (e.g. car
                    unsupervised) or keys safekeeping (e.g. left in the
                    ignition).
                  </p>
                  <p className="mb-4">
                    * Excess amounts vary by country and should be checked at
                    the time of booking. Alternatively, contact the Budget
                    call-centre or local branch for more details.
                  </p>
                  <p className="mb-4">
                    Coverages reduce the liability payable from the full cost of
                    the vehicle to the standard excess. Excess amounts vary by
                    country and should be checked at the time of booking.
                    Alternatively, contact our reservation team or location for
                    more details. You may find out more information on rental
                    location’s terms and conditions.{" "}
                  </p>
                  <p className="mb-4">
                    Should you require Excess Reduction or Zero Excess products,
                    they are available at rental counter in some rental
                    locations. Please check with our rental agent at counter
                    upon your car pick-ups.
                  </p>
                  {/* TP */}
                </div>
              ) : this.props.data === "Personal Accident and Effects (PAE)" ? (
                <div className="col-sm-12">
                  <h1>{this.props.data}</h1>

                  {/* PAE */}
                  <b>For rentals in the USA</b>
                  <p className="mb-4">
                    You’ll pay for{" "}
                    <b>Personal Accident and Effects Insurance</b> if you accept
                    it. You understand that you will be charged the rate per day
                    for a full day even if you don’t have the car the entire
                    day.
                  </p>
                  <b>
                    <u>
                      PAE – Personal Accident & Effects Insurance (where
                      available)
                    </u>
                  </b>
                  <p className="mb-4">
                    PAE provides medical, hospital, death and dismemberment
                    benefits for bodily injury resulting from an accident, and
                    benefits for loss of or damage to certain personal property
                    while in the rented vehicle. Coverage is for the term of the
                    rental agreement and will not exceed a 30-day consecutive
                    period.
                  </p>
                  <p className="mb-4">
                    PAE provides the following maximum benefits for accidental
                    bodily injury:
                  </p>
                  <ul className="mb-4">
                    <li>
                      • Accidental Death and Dismemberment up to $175,000*
                      (renter) and $25,000** (passenger).
                    </li>
                    <li>• Accident Medical Expenses up to $10,000</li>
                    <li>
                      • Hospital Accident Benefit*** of $500 per day up to 30
                      days
                    </li>
                    <li>
                      • Personal Effects coverage for each item is $1,000;
                      maximum for all covered items in rental vehicle is
                      $3,000****
                    </li>
                  </ul>
                  <p className="mb-4">*$200,000 in NY; $50,000 in NH</p>
                  <p className="mb-4">
                    **$200,000 in NY; $175,000 in CT, IN, KS, MO, MT, VT, VA,
                    WA; $50,000 in NH
                  </p>
                  <p className="mb-4">
                    ***Hospital Accident Benefit not available in NY
                  </p>
                  <p className="mb-4">****$2,000 in NY</p>
                  <b>
                    <u>
                      PAE Plus – Personal Accident & Effects Insurance PLUS
                      (where available)
                    </u>
                  </b>
                  <p className="mb-4">
                    PAE provides medical, hospital, death and dismemberment
                    benefits for bodily injury resulting from an accident, and
                    benefits for loss of or damage to certain personal property
                    while in the rented vehicle. Coverage is for the term of the
                    rental agreement and will not exceed a 30-day consecutive
                    period.
                  </p>
                  <p className="mb-4">
                    PAE Plus provides the following maximum benefits for
                    accidental bodily injury:
                  </p>
                  <ul className="mb-4">
                    <li>
                      • Accidental Death and Dismemberment up to $250,000*
                      (renter) and $125,000** (passenger) for vehicles with a
                      capacity of up to 15 occupants
                    </li>
                    <li>• Accident Medical Expenses up to $15,000</li>
                    <li>
                      • Hospital Accident Benefit*** of $500 per day up to 30
                      days
                    </li>
                    <li>
                      • Personal Effects coverage for each item is $1,000;
                      maximum for all covered individuals in rental vehicle is
                      $3,000.
                    </li>
                  </ul>
                  <p className="mb-4">*$50,000 in NH</p>
                  <p className="mb-4">
                    **$250,000 in NY; $250,000 in CT, IN, KS, MO, MT, VT, VA,
                    WA; $50,000 in NH
                  </p>
                  <p className="mb-4">
                    ***Hospital Accident Benefit not available in NY
                  </p>
                  <p className="mb-4">****$2,000 in NY</p>

                  <p className="mb-4">
                    For the full brochure, please{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-us-protections-coverages-brochure-062321.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      click here
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    For the Personal Accident policy certificate for General
                    States, please{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pai-cert-general-states.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      click here
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    For the Personal Accident policy certificate for New York,
                    please{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pai-cert-ny.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      click here
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    For the Personal Accident policy certificates for
                    Connecticut, Indiana, Kansas, Missouri, Montana, Vermont,
                    Virginia, and Washington, please{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pai-cert-CT-IN-KS-MO-MT-VT-VA-WA.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      click here
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    For the Personal Effects policy certificate for General
                    States, please{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pep-cert-general-states.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      click here
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    For the Personal Effects policy certificate for New York,
                    please{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pep-cert-ny.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      click here
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    For the Personal Effects policy certificates for
                    Connecticut, Indiana, Kansas, Missouri, Montana, Vermont,
                    Virginia, and Washington, please{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pep-cert-CT-IN-KS-MO-MT-VT-VA-WA.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      click here
                    </a>
                    .
                  </p>
                  {/* PAE */}
                </div>
              ) : (
                <div className="col-sm-12">
                  <h1>{this.props.data}</h1>

                  {/* SLI */}
                  <b>For rentals in the USA and Canada</b>
                  <p className="mb-4">
                    If you elect to purchase SLI, coverage will be provided to
                    you and any authorized driver under an excess automobile
                    policy issued to Budget. SLI provides protection for third
                    party automobile claims for the difference between the
                    minimum financial responsibility limits provided under
                    paragraph 22 above and a maximum combined single limit of
                    liability of $1,000,000 or $2,000,000 depending on the
                    jurisdiction of rental and vehicle class for bodily injury,
                    death or property damage for each accident. This coverage is
                    provided under a policy of excess liability insurance more
                    fully described in the available brochure and is subject to
                    all of the conditions and limitations described in paragraph
                    22 above, except that notwithstanding anything contained in
                    the Agreement, the terms of the policy will at all times
                    control.{" "}
                    <b>
                      SLI does not apply to liability for bodily injury or
                      property damage arising out of any "prohibited use of the
                      car" as described in paragraph 15 of the Agreement, all of
                      which are exclusions to SLI. Other exclusions to SLI are
                      listed in the SLI policy
                    </b>
                    . You understand that you will be charged the rate per day
                    for a full day even if you don’t have the car for the entire
                    day.
                    <b>
                      Disclosures Regarding Supplemental Liability Insurance In
                      the State of Florida
                    </b>
                  </p>
                  <b>What is Supplemental Liability Insurance (SLI)?</b>
                  <p className="mb-4">
                    <b>Budget</b> has Supplemental Liability Insurance (SLI)
                    available at most US locations. SLI is a special optional
                    service offered by Budget when you rent a car from Budget.
                    It’s an "Excess Automobile Liability Insurance Policy" that
                    provides additional liability insurance, within specified
                    limits, above the limits provided in the Agreement. SLI
                    insures you, and authorized operators as defined in the
                    Agreement against claims made by third parties against you,
                    the customer, for bodily injury/death and property damage
                    caused by the use or operation of a Budget rental car as
                    permitted in the Agreement. SLI is a separate insurance
                    policy issued to Budget by ACE American Insurance Company.
                  </p>
                  <p className="mb-4">
                    If you elect to accept SLI, you will pay an additional daily
                    charge as shown on the Agreement.{" "}
                    <b>
                      The purchase of SLI is not required in order to rent a car
                      from Budget
                    </b>
                    .{" "}
                  </p>
                  <b>What are the coverage limits provided by SLI?</b>
                  <p className="mb-4">
                    The SLI coverage limits equal the difference between the SLI
                    maximum $1,000,000 combined single limit ($2,000,000
                    combined single limit in the following jurisdictions:
                    California, Florida, Hawaii and New York) and the liability
                    protection limits provided under the Budget rental
                    agreement.{" "}
                    <b>
                      Note, in all states we only offer $1,000,000 combined
                      single limit on 10, 12, and 15 passenger vans
                    </b>
                    .
                  </p>
                  <b>When and where does SLI coverage apply?</b>
                  <p className="mb-4">
                    You and authorized operators are covered while driving the
                    rental car within the United States and Canada, but only if
                    the car is rented and returned in the United States.
                    Coverage does not apply in Mexico.
                  </p>
                  <b>How do I report a claim?</b>
                  <p className="mb-4">
                    If you are involved in an accident, you must complete an
                    accident report and deliver it to the Budget rental
                    location. In order to make a claim or give notice of a
                    claim, send written notice to:
                  </p>
                  <p className="mb-4">Budget Rent A Car System, Inc.</p>
                  <p className="mb-4">Attn: Claims</p>
                  <p className="mb-4">P.O. Box 61247</p>
                  <p className="mb-4">Virginia Beach, VA 23466</p>
                  <p className="mb-4">1-866-446-8376</p>
                  <b>
                    How does SLI affect the application of your automobile or
                    umbrella insurance policy?
                  </b>
                  <p className="mb-4">
                    Your personal insurance policy providing coverage on an
                    owned automobile, or other personal policy, may provide
                    additional coverage, and to that extent, SLI may provide a
                    duplication of coverage. Whether, at what point and to what
                    extent your own policies apply can only be determined by
                    checking the terms of the policies themselves as these terms
                    frequently vary. However, the protection afforded by SLI (if
                    SLI is accepted), like the Budget rental agreement limits of
                    protection, is primary to your own policies. This means that
                    before your own policies would apply to pay a claim, the
                    $1,000,000 protection (or $2,000,000 combined single limit
                    in the following jurisdictions: California, Florida, Hawaii
                    and New York) afforded by the combination SLI and the Budget
                    rental agreement limits would have to be exhausted.
                  </p>
                  <b>
                    Note, in all states we only offer $1,000,000 combined single
                    limit on 10,12, and 15 passenger vans.
                  </b>
                  <p className="mb-4"></p>
                  <b>What exclusions apply to SLI?</b>
                  <p className="mb-4">
                    The following highlights some of the exclusions that would
                    preclude SLI. It is important that you read the rental
                    document and the policy carefully for all exclusions.
                  </p>
                  <ul className="mb-4">
                    <li>
                      • Any Prohibited Use of the Car as described in paragraph
                      15 of the Agreement
                    </li>
                    <li>
                      • Bodily injury to or Property Damage to any Insured; nor,
                      to the extent permitted by law in the state where the
                      rental document is signed at time of rental/where the car
                      is rented, to Bodily Injury or Property Damage to any
                      person who is related to any Insured by blood, marriage or
                      adoption and residing in the same household.{" "}
                    </li>
                    <li>
                      • "Uninsured Motorist"/"Underinsured Motorist" coverage
                      are not provided by the policy except in states where
                      mandated by law up to maximum amount of $100,000 ($1MM in
                      FL and NH) or in such amounts as mandated by law
                    </li>
                    <li>
                      • "No Fault" and other supplemental or optional coverages
                      are not provided by the policy
                    </li>
                    <li>
                      • Punitive or exemplary damages to the extent permitted by
                      law
                    </li>
                  </ul>
                  <b>
                    Are there any special restrictions on the purchase of SLI?
                  </b>
                  <p className="mb-4">
                    In Florida, SLI may not be purchased if the term of the
                    rental document is for more than 30 days, coverage may not
                    be provided for more than 30 consecutive days; and if the
                    rental document is extended beyond 30 days, the coverage may
                    be extended for one time only, for a period not to exceed 30
                    days.
                  </p>
                  <p className="mb-4">
                    For more information call Budget toll-free at:
                    1-800-527-7000
                  </p>
                  <p className="mb-4">Visit Budget online at Budget.com</p>
                  <p className="mb-4">
                    This is a summary only of SLI. The specific terms,
                    conditions and exclusions thereof are subject to all
                    provisions, limitations and exclusions contained in the
                    rental document and the SLI policy issued by ACE American
                    Insurance Company, one of the U.S.-based subsidiaries of ACE
                    Limited (NYSE: ACE) "ACE" and the ACE logo are service marks
                    of the ACE Group, which is comprised of ACE Limited and its
                    subsidiaries.
                  </p>
                  <p className="mb-4">
                    This summary is not intended to provide a complete
                    description of the policy’s terms, conditions and
                    exclusions. For additional details, we invite you to examine
                    a copy of the policy, which is available for your
                    inspection, upon request, at the Budget location at which
                    you are renting.
                  </p>
                  <p className="mb-4">
                    Budget employees, agents, or endorsees are not qualified to
                    evaluate the adequacy of the renter’s existing coverage.
                  </p>
                  {/* SLI */}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-sm-12 mt-2">
                <Link
                  to={`${process.env.PUBLIC_URL}/makereservation`}
                  className="btnCar"
                >
                  Make Reservation
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
