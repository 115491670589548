import React from "react";
import Card from "../../common/card/Card";

const ContentAbout = () => {
  return (
    <div className="carsContainer">
      <div className="container py-5 px-4">
        <div className="row py-4">
          <Card
            title="Company Information"
            description="Budget combines the strength of one of the world's largest car rental brands together with detailed local knowledge, flexibility and great value for money."
            link="/about-budget/company-information"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentAbout;
