import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";

const TermConditions = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="container mt-5">
      <button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="text-start mb-4"
      >
        <span className="yellow me-1">Terms and Conditions</span>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/icon/arrow.png`}
          alt="arrow"
          width={10}
          className={`expand ${open ? "active" : ""}`}
        />
      </button>
      <Collapse in={open}>
        <div className="termMembers row example-collapse-text mb-5">
          <p className="mb-2">
            <b>Up to 30% off base rates</b>
          </p>
          <ul className="listTerm ps-4">
            <li>
              The savings of up to 30% applies to Budget base rates and is
              applicable only to the time and mileage charges of the rental.
            </li>
            <li>Offer does not apply to car group X.</li>
            <li>
              All taxes, fees (including but not limited Air Conditioning Excise
              Recovery Fee, Concession Recovery Fee, Vehicle License Recovery
              Fee, Energy Recovery Fee, Tire Management Fee, and Frequent
              Traveler Fee) and surcharges (including but not limited to
              Customer Facility Charge and Environmental Fee Recovery Charge)
              are extra.
            </li>
            <li>
              Offer is available for U.S. and Canadian residents only for
              rentals at participating locations in the U.S and Canada.
            </li>
            <li>
              Offer may not be used in conjunction with any other BCD number,
              promotion or offer.
            </li>
            <li>Weekly rates require a minimum five day rental period.</li>
            <li>
              Weekend rate available Thursday noon; car must be returned by
              Monday 11:59 p.m., or higher rate will apply.
            </li>
            <li>
              A Saturday night keep and an advance reservation may be required.
            </li>
            <li>
              Offer is subject to vehicle availability at the time of rental and
              may not be available on some rates at some times, including some
              online rates at Budget.com.
            </li>
            <li>Car rental return restrictions may apply.</li>
            <li>Offer subject to change without notice.</li>
            <li>Holiday and other blackout periods may apply.</li>
            <li>
              Renter must meet Budget age, driver and credit requirements.
              Minimum age may vary by location. An additional daily surcharge
              may apply for renters under 25 years old.
            </li>
          </ul>
          <p className="mt-3 mb-2">
            <b>3% credit on next rental</b>
          </p>
          <ul className="listTerm ps-4">
            <li>This is offer is only valid for AARP Members.</li>
            <li>
              AARP members will be required to validate their AARP membership.
            </li>
            <li>
              AARP Members must book their reservation using Budget.com in order
              to earn 3% of Time and Mileage back to towards their next rental.
            </li>
            <li>
              The 3% credit will be automatically applied to their next rental.
            </li>
            <li>
              AARP members will be required to use their dedicated AARP BCD.
            </li>
            <li>Credit will only be valid for 12 months.</li>
            <li>Valid at participating U.S. rental locations.</li>
          </ul>
        </div>
      </Collapse>
    </div>
  );
};

export default TermConditions;
